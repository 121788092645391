import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import TableWidget from "./OtiumTableWidget.vue";

export default Node.create({
  name: "otiumTable",

  group: "block",
  inline: false,
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      data: {
        default: ""
      },
      title: {
        default: ""
      },
      footnotes: {
        default: ""
      },
      tableid: {
        default: ""
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "otium-table"
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["otium-table", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(TableWidget);
  }
});
