






























import { TemplateSectionEditModel } from "@/api/OtiumAppApi";
import { useProjectStore } from "@/stores/projectStore";
import { computed, defineComponent } from "@vue/composition-api";
import EditorReadonly from "../Editor/EditorReadonly.vue";

export default defineComponent({
  props: {
    section: {
      type: TemplateSectionEditModel,
      required: true
    }
  },
  setup(props, context) {
    const projectStore = useProjectStore();

    const edit = () => {
      context.emit("edit");
    };
    function createSubsection() {
      context.emit("createSubsection", {
        sectionId: props.section.sectionId,
        subsections: props.section.subsections,
        sectionNumber: sectionNumber.value,
        sectionTitle: props.section.version.title
      });
    }

    const sectionNumber = computed(() => props.section.sectionNumber.map((n) => n + 1).join("."));
    const headerTag = computed(() => {
      const depth = props.section.sectionNumber.length;
      if (depth > 6) return "h6";
      else if (depth < 1) return "h1";
      else return "h" + depth;
    });

    return {
      edit,
      createSubsection,
      projectStore,
      sectionNumber,
      headerTag
    };
  },
  components: { EditorReadonly }
});
