






























































































































































































import { defineComponent, ref, onBeforeUnmount, PropType } from "@vue/composition-api";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import SpanNode from "./widgets/SpanNode";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import ProjectNodeCreate from "./widgets/ProjectVariable/Extensions";
import TemplateNodeCreate from "./widgets/TemplateVariable/Extensions";
import {
  TemplateAbbreviation,
  TemplateVerboseAbbreviation
} from "./widgets/TemplateAbbreviation/Extensions";
import {
  ProjectAbbreviation,
  ProjectVerboseAbbreviation
} from "./widgets/ProjectAbbreviation/Extensions";
import { DocumentVariableModel, TemplateVariableModel } from "@/api/OtiumAppApi";
import { useProjectStore } from "@/stores/projectStore";
import { useTemplateEditorStore } from "@/stores/templateEditorStore";

export default defineComponent({
  components: {
    EditorContent
  },

  props: {
    /**
     * Whether we are editing a document or not
     */
    isDocument: {
      type: Boolean,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    /**
     * Whether to include the table actions. This will default to having a 3/3 table added.
     */
    includeTable: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to include the button for paragraph formatting
     */
    includeParagraph: {
      type: Boolean,
      default: false
    },
    /**
     * The placeholder text to display in the editor
     */
    placeholder: {
      type: String,
      default: "Value"
    }
  },

  setup(props, ctx) {
    let content = props.includeParagraph ? "<span></span>" : "<div></div>";

    if (props.value) {
      content = props.value;
    }

    let variables: DocumentVariableModel[] | TemplateVariableModel[] = [];

    if (props.isDocument) {
      const projectStore = useProjectStore();
      variables = projectStore.documentVersionVariables;
    } else {
      const templateStore = useTemplateEditorStore();
      variables = templateStore.templateVersionVariables;
    }

    const placeholder = props.placeholder;

    const editor = ref(
      new Editor({
        content: content,

        onUpdate: () => {
          if (!editor.value) return;
          ctx.emit("input", editor.value.getHTML());
        },
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: placeholder
          }),
          Underline,
          TextAlign.configure({
            types: ["paragraph"]
          }),
          TextStyle,
          SpanNode,
          Table.configure({
            resizable: true
          }),
          TableRow,
          TableHeader,
          TableCell,
          props.isDocument ? ProjectNodeCreate : TemplateNodeCreate,
          props.isDocument ? ProjectAbbreviation : TemplateAbbreviation,
          props.isDocument ? ProjectVerboseAbbreviation : TemplateVerboseAbbreviation
        ]
      })
    );

    if (props.includeTable && !props.value) {
      editor.value.chain().focus().insertTable({ rows: 3, cols: 3 }).run();
    }

    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    const insertVariable = (name: string) => {
      const variableTag = `<otium-variable name="${name}"></otium-variable>`;
      editor.value.chain().focus().insertContent(variableTag).run();
    };

    return {
      editor,
      insertVariable,
      variables
    };
  }
});
