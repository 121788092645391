














































































































































































































































































































































































































































































import { defineComponent, PropType, ref } from "@vue/composition-api";
import { useEditorStore } from "@/stores/editorStore";
import {
  DocumentAbbreviationModel,
  TemplateAbbreviationModel,
  DocumentVariableModel,
  TemplateVariableModel,
  DocumentReferenceModel
} from "@/api/OtiumAppApi";
import { ChartData, convertToChartjs } from "./widgets/ProjectChart/chartDataConversion";
import ChartDataEditorDialog from "./widgets/ProjectChart/ChartDataEditorDialog.vue";
import TableEditorDialog from "@/components/Editor/widgets/Tables/TableEditorDialog.vue";
import SectionLinkDialog from "@/components/projects/SectionLinkDialog.vue";
import ChartLinkDialog from "@/components/projects/ChartLinkDialog.vue";
import TableLinkDialog from "@/components/projects/TableLinkDialog.vue";
import { Chart } from "chart.js";
import { v4 as uuidv4 } from "uuid";
import { TableData } from "./widgets/Tables/tableDataConversion";
import { HelpSection } from "@/constants/helpSections";
import { useHelpDialogStore } from "@/stores/helpDialogStore";

export default defineComponent({
  props: {
    isDocument: {
      type: Boolean,
      default: true
    },
    variables: {
      type: Array as PropType<DocumentVariableModel[] | TemplateVariableModel[]>,
      required: false
    },
    abbreviations: {
      type: Array as PropType<DocumentAbbreviationModel[] | TemplateAbbreviationModel[]>,
      required: false
    },
    references: {
      type: Array as PropType<DocumentReferenceModel[]>,
      required: false
    }
  },

  components: {
    ChartDataEditorDialog,
    SectionLinkDialog,
    ChartLinkDialog,
    TableEditorDialog,
    TableLinkDialog
  },

  setup() {
    const editorStore = useEditorStore();

    /** Charts  */
    const chartDataEditorDialogVisible = ref(false);
    const currentChartData = ref(null as ChartData | null);
    const addEditChartClicked = () => {
      if (editorStore.editor === null) return;

      const chartAttrs = editorStore.editor.getAttributes("otiumChart");
      if (chartAttrs != undefined && chartAttrs.data != undefined) {
        try {
          currentChartData.value = JSON.parse(chartAttrs.data);
        } catch (e) {
          return;
        }
      } else {
        currentChartData.value = null;
      }

      chartDataEditorDialogVisible.value = true;
    };

    let chart = null as Chart | null;
    let canvasContext = null as CanvasRenderingContext2D | null;

    const saveChart = (newChartData: ChartData) => {
      if (editorStore.editor === null) return;

      const canvas = document.getElementById("canvasDrawing") as HTMLCanvasElement;
      const chartJSData = convertToChartjs(newChartData);

      if (canvasContext == null) canvasContext = canvas.getContext("2d") ?? null;
      if (chart != null) chart.destroy();
      if (canvasContext != null) chart = new Chart(canvasContext, chartJSData);
      const image = canvas.toDataURL();

      if (editorStore.editor.getAttributes("otiumChart").data == undefined) {
        // no chart selected, insert
        const chartTag = document.createElement("otium-chart");
        chartTag.setAttribute("data", JSON.stringify(newChartData));
        chartTag.setAttribute("title", newChartData.title);
        chartTag.setAttribute("chartid", uuidv4());
        chartTag.setAttribute("image", image);

        editorStore.insertChart(chartTag.outerHTML);
      } else {
        // chart selected, edit
        editorStore.updateChart(JSON.stringify(newChartData), image);
      }

      chartDataEditorDialogVisible.value = false;
    };

    /** Tables  */
    const tableEditorDialogVisible = ref(false);
    const currentTableData = ref(null as TableData | null);
    const addEditTableClicked = () => {
      if (editorStore.editor === null) return;

      const chartAttrs = editorStore.editor.getAttributes("otiumTable");

      if (chartAttrs != undefined && chartAttrs.data != undefined) {
        try {
          currentTableData.value = {
            title: chartAttrs.title,
            footnotes: chartAttrs.footnotes,
            data: chartAttrs.data
          };
        } catch (e) {
          return;
        }
      } else {
        currentTableData.value = {
          title: "",
          footnotes: "",
          data: ""
        };
      }

      tableEditorDialogVisible.value = true;
    };

    const saveTable = (newTableData: TableData) => {
      if (editorStore.editor === null) return;

      if (editorStore.editor.getAttributes("otiumTable").data == undefined) {
        // no table selected, insert
        const chartTag = document.createElement("otium-table");
        chartTag.setAttribute("data", newTableData.data);
        chartTag.setAttribute("title", newTableData.title);
        chartTag.setAttribute("footnotes", newTableData.footnotes);
        chartTag.setAttribute("tableid", uuidv4());

        editorStore.insertOtiumTable(chartTag.outerHTML);
      } else {
        editorStore.updateOtiumTable(newTableData);
      }

      tableEditorDialogVisible.value = false;
    };

    // Section Link
    let addSectionLinkDialogOpen = ref(false);

    // Chart link
    let addChartLinkDialogOpen = ref(false);

    // Table Link
    let addTableLinkDialogOpen = ref(false);

    // Links
    const createSectionLink = (sectionData: any) => {
      editorStore.insertSectionLink(sectionData.id, sectionData.value);
      addSectionLinkDialogOpen.value = false;
      addChartLinkDialogOpen.value = false;
      addTableLinkDialogOpen.value = false;
    };

    function openToolboxHelper() {
      const helpDialogStore = useHelpDialogStore();
      helpDialogStore.openDialog(HelpSection.Toolbox);
    }

    return {
      editorStore,
      chartDataEditorDialogVisible,
      currentChartData,
      addEditChartClicked,
      saveChart,
      addSectionLinkDialogOpen,
      createSectionLink,
      addChartLinkDialogOpen,
      tableEditorDialogVisible,
      currentTableData,
      addEditTableClicked,
      saveTable,
      addTableLinkDialogOpen,
      openToolboxHelper
    };
  }
});
