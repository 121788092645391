import { PropType } from "@vue/composition-api";
import { Decoration } from 'prosemirror-view';
import { Node as ProseMirrorNode } from 'prosemirror-model';

export default {
    editor: {
        type: Object as () => import("@tiptap/core").Editor,
        required: true
    },
    node: {
        type: Object as () => ProseMirrorNode<any>,
        required: true

    },
    decorations: {
        type: Object as () => Decoration<{
            [key: string]: any;
        }>[],

    },
    selected: {
        type: Boolean,

    },
    extension: {
        type: Object as () => import("@tiptap/core").Node<any, any>,

    },
    getPos: {
        type: Object as () => () => number,

    },
    updateAttributes: {
        type: Object as () => (attributes: Record<string, any>) => void,
        required: true

    },
    deleteNode: {
        type: Object as () => () => void,

    }
}
