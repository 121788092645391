





























import {
  defineComponent,
  ref,
  watch,
  toRefs,
} from "@vue/composition-api";

export default defineComponent({
  name: "ChartDataEditorColorPicker",
  components: {},
  model: {
    prop: "color",
    event: "save",
  },
  props: {
    color: {
      type: String,
      default: null,
    },
  },
  emits: ["save"],
  setup(props, { emit }) {
    const { color } = toRefs(props);

    const visible = ref(false);

    const selectedColor = ref(color.value);
    watch(color, () => {
      selectedColor.value = color.value;
    });

    function save() {
      visible.value = false;
      emit("save", selectedColor.value);
    }

    return {
      visible,
      selectedColor,
      save,
    };
  },
});
