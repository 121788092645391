import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import TemplateAbbreviationWidget from "./TemplateAbbreviationWidget.vue";
import TemplateVerboseAbbreviationWidget from "./TemplateVerboseAbbreviationWidget.vue";

export const TemplateAbbreviation = Node.create({
  name: "otiumAbbreviation",

  group: "inline",
  inline: true,
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      name: {
        default: "test"
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "otium-abbreviation"
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["otium-abbreviation", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(TemplateAbbreviationWidget);
  }
});

export const TemplateVerboseAbbreviation = Node.create({
  name: "otiumVerboseAbbreviation",

  group: "inline",
  inline: true,
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      name: {
        default: "test"
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "otium-verbose-abbreviation"
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["otium-verbose-abbreviation", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(TemplateVerboseAbbreviationWidget);
  }
});
