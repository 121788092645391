



































import { computed, defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";
import NodeProps from "../NodeProps";
import Vue from "vue";

export default defineComponent({
  name: "OtiumTable",
  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  props: NodeProps,

  setup(props) {
    const { node, editor } = toRefs(props);

    const replaceVariables = (tableData: string): string => {
      var isMore = true;
      while (isMore) {
        let startIndex = tableData.indexOf("<otium-variable name");
        let endIndex = tableData.indexOf("</otium-variable>");

        if (!startIndex || startIndex < 0) {
          isMore = false;
        } else {
          let startOfElement = tableData.substring(startIndex, endIndex);

          let startOfNameIndex = startOfElement.indexOf("name=");

          if (startOfNameIndex > 0 && startOfNameIndex < startOfElement.length + 6) {
            let name = startOfElement.substring(startOfNameIndex + 6, startOfElement.length - 2);

            tableData = tableData.replace(startOfElement + "</otium-variable>", name);
          }
        }
      }
      return tableData;
    };

    let title = ref(node?.value?.attrs?.title);
    let footnotes = ref(node?.value?.attrs?.footnotes);
    let data = ref(replaceVariables(node?.value?.attrs?.data as string));
    let id = ref(node?.value?.attrs?.tableid);

    if (node != null) {
      watch(node, () => {
        data.value = node?.value?.attrs?.data;
        title.value = node?.value?.attrs?.title;
        footnotes.value = replaceVariables(node?.value?.attrs?.footnotes as string);
        id.value = node?.value?.attrs?.tableid;
      });
    }

    const editable = computed(() => {
      return editor?.value?.options.editable ?? false;
    });

    const hovered = ref(false);

    function openEditor() {
      if (!editable.value) return;
      Vue.nextTick(() => {
        if (props.getPos != undefined) editor?.value?.commands.setNodeSelection(props.getPos());
        const el = document.getElementById("editor_add_edit_table_button") as HTMLButtonElement | null;
        if (el != null) el.click();
      });
    }

    return {
      editable,
      hovered,
      openEditor,
      title,
      footnotes,
      data,
      id
    };
  }
});
