

















































































import {
  CreateTemplateAbbreviationModel,
  TemplateAbbreviationModel,
  TemplateAbbreviationsClient,
  UpdateTemplateAbbreviationValueModel
} from "@/api/OtiumAppApi";
import { useTemplateEditorStore } from "@/stores/templateEditorStore";
import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    openedDialogs: {
      type: Object,
      required: true
    },
    editingAbbreviation: {
      type: TemplateAbbreviationModel
    }
  },
  setup(props, context) {
    const templateStore = useTemplateEditorStore();
    const editAbbreviationValue = ref("");
    watch(
      () => props.editingAbbreviation,
      (newValue) => {
        if (newValue) {
          editAbbreviationValue.value = newValue.version.value;
        }
      }
    );

    const newAbbreviationValue = ref("");
    const newAbbreviationName = ref("");

    const savingNewAbbreviation = ref(false);
    const saveNewAbbreviation = async () => {
      if (!templateStore.templateVersionIdInContext) return;
      savingNewAbbreviation.value = true;

      const client = new TemplateAbbreviationsClient();
      await client.createAbbreviation(templateStore.templateVersionIdInContext, {
        value: newAbbreviationValue.value,
        name: newAbbreviationName.value
      } as CreateTemplateAbbreviationModel);

      savingNewAbbreviation.value = false;
      props.openedDialogs.add = false;
      newAbbreviationValue.value = "";
      newAbbreviationName.value = "";
    };

    const savingEditAbbreviation = ref(false);
    const saveEditAbbreviation = async () => {
      if (!props.editingAbbreviation) return;
      savingEditAbbreviation.value = true;
      const client = new TemplateAbbreviationsClient();
      await client.updateAbbreviation(props.editingAbbreviation.id, {
        value: editAbbreviationValue.value
      } as UpdateTemplateAbbreviationValueModel);

      savingEditAbbreviation.value = false;
      props.openedDialogs.edit = false;
    };

    return {
      templateStore,
      editAbbreviationValue,
      savingEditAbbreviation,
      saveEditAbbreviation,

      newAbbreviationName,
      newAbbreviationValue,
      savingNewAbbreviation,
      saveNewAbbreviation
    };
  }
});
