import {
  SectionVersionHeading,
  TemplateEditorClient,
  TemplateEditModel,
  TemplateSectionHeading,
  TemplateSectionEditModel,
  TemplateAbbreviationsClient,
  TemplateAbbreviationModel,
  TemplateVariablesClient,
  TemplateVariableModel,
  TemplateSectionVersionHeadingModel,
  TemplateVersionHeadingModel,
  UpdateTemplateSectionOrderRequest,
  PublishTemplateVersionRequest,
  UpdateTemplateSectionRequest
} from "@/api/OtiumAppApi";
import { FailedToNegotiateWithServerError } from "@microsoft/signalr/dist/esm/Errors";
import { defineStore } from "pinia";
import Vue from "vue";

export type TemplateSections = {
  template: TemplateEditModel;
  sections: SectionVersionHeading[];
};

export type TemplateTree = {
  id: string;
  name: string;
  templateVersionId: string;
  type: TemplatePartType;
  children: TemplateTree[] | undefined;
};

export enum TemplatePartType {
  Template,
  TemplateVersion,
  Section,
  SectionVersion
}

const MAX_SUBSECTIONS = 10;

export const useTemplateEditorStore = defineStore({
  id: "template-editor",
  state: () => ({
    /**
     * The id of the project being edited.
     */
    templateId: undefined as string | undefined,

    /**
     * The id of the template version the user has open.
     */
    templateVersionIdInContext: undefined as string | undefined,

    /**
     * The id of the section the user has selected to edit.
     */
    sectionIdInContext: undefined as string | undefined,
    sectionVersionIdInContext: undefined as string | undefined,

    /**
     * The sections loaded for each document.
     */
    template: undefined as TemplateEditModel | undefined,

    /**
     * Holds the contents of sections for the template version id in context.
     */
    sectionDetailsTree: [] as TemplateSectionEditModel[],

    /**
     * Holds the flattened sections
     */
    flattenedSectionDetails: [] as TemplateSectionEditModel[],

    /**
     * If we are connected to the editor hub for live updates.
     */
    isHubConnected: false,

    sectionHeadings: {} as { [templateVersionId: string]: TemplateSectionHeading[] },

    /**
     * The variables for the template version in context.
     */
    templateVersionVariables: [] as TemplateVariableModel[],

    /**
     * The abbreviations for the template version in context.
     */
    templateVersionAbbreviations: [] as TemplateAbbreviationModel[]
  }),

  getters: {
    hasTemplateVersionInContext: (state) => state.templateVersionIdInContext !== undefined,
    hasSectionInContext: (state) => state.sectionIdInContext !== undefined,

    sectionDetailsInContext: (state) => {
      if (!state.sectionIdInContext) return undefined;

      return state.flattenedSectionDetails.find((x) => x.sectionId === state.sectionIdInContext);
    },

    isPublishedVersionInContext: (state) =>
      state.template?.publishedVersionId === state.templateVersionIdInContext,
    publishedVersionId: (state) => state.template?.publishedVersionId,
    /**
     * A tree structure representing a table of contents for the template.
     * @returns
     */
    templateTree(): TemplateTree[] {
      function getSubsectionsRecursive(
        sectionHeading: TemplateSectionHeading,
        maxDepth: number
      ): TemplateTree[] | undefined {
        if (maxDepth <= 0) return undefined;

        const tree = sectionHeading.subSectionHeadings.map((subsection): TemplateTree => {
          return {
            id: subsection.templateSectionId,
            templateVersionId: subsection.templateVersionId,
            name: subsection.version.title,
            type: TemplatePartType.Section,
            children: getSubsectionsRecursive(subsection, maxDepth - 1)
          };
        });

        if (tree.length < 1) return undefined;
        else return tree;
      }

      if (!this.template) return [];
      return this.template.versions.map((version) => {
        return {
          id: version.templateVersionId,
          templateVersionId: version.templateVersionId,
          name: version.versionName,
          type: TemplatePartType.TemplateVersion,
          activeVersion: version.templateVersionId === this.publishedVersionId,
          children: this.sectionHeadings[version.templateVersionId]
            ? (this.sectionHeadings[version.templateVersionId].map((section) => {
                return {
                  id: section.templateSectionId,
                  templateVersionId: version.templateVersionId,
                  name: section.version.title,
                  type: TemplatePartType.Section,
                  children: getSubsectionsRecursive(section, MAX_SUBSECTIONS)
                };
              }) as TemplateTree[])
            : ([] as TemplateTree[])
        } as TemplateTree;
      });
    }
  },

  actions: {
    async loadTemplate(templateId: string) {
      this.templateId = templateId;

      await this.refreshTemplate();
    },

    /**
     * Refresh the entire state of a project.
     * @returns
     */
    async refreshTemplate() {
      if (!this.templateId) return;

      const templateEditorClient = new TemplateEditorClient();
      this.template = await templateEditorClient.getTemplateHeading(this.templateId);

      if (this.templateVersionIdInContext) {
        await this.loadSections(this.templateVersionIdInContext);
      }
    },

    /**
     * Loads the sections for a template version.
     */
    async loadSections(templateVersionId: string) {
      const client = new TemplateEditorClient();

      const model = await client.getContent(templateVersionId);
      this.sectionDetailsTree = model.sections;
      this.flattenSections();
      await this.loadSectionHeadings(templateVersionId);
    },

    async loadSectionHeadings(templateVersionId: string) {
      const client = new TemplateEditorClient();
      const sectionHeadings = await client.getTemplateVersionSectionHeadings(templateVersionId);
      Vue.set(this.sectionHeadings, templateVersionId, sectionHeadings);
    },

    orderSections() {
      this.orderSectionsRecursive(this.sectionDetailsTree, MAX_SUBSECTIONS + 1, undefined);
    },
    orderSectionsRecursive(
      sections: TemplateSectionEditModel[],
      maxDepth: number,
      sectionNumber: number[] | undefined
    ) {
      if (maxDepth <= 0) return [];
      sections.sort((a, b) => a.order - b.order);
      sections.forEach((section, index) => {
        if (sectionNumber) {
          section.sectionNumber = [...sectionNumber, index];
        } else {
          section.sectionNumber = [index];
        }
        this.orderSectionsRecursive(section.subsections, maxDepth - 1, section.sectionNumber);
      });
    },

    flattenSections() {
      this.flattenedSectionDetails = this.getFlattenedSectionDetailsRecursive(
        this.sectionDetailsTree,
        MAX_SUBSECTIONS + 1
      );
    },
    getFlattenedSectionDetailsRecursive(
      sections: TemplateSectionEditModel[],
      maxDepth: number
    ): TemplateSectionEditModel[] {
      if (maxDepth <= 0) return [];

      let flattened: TemplateSectionEditModel[] = [];

      sections.sort((a, b) => a.order - b.order);
      sections.forEach((section) => {
        let localFlattened: TemplateSectionEditModel[] = [section];
        localFlattened = localFlattened.concat(
          this.getFlattenedSectionDetailsRecursive(section.subsections, maxDepth - 1)
        );
        flattened = flattened.concat(localFlattened);
      });

      return flattened;
    },

    getSectionFromSectionNumber(
      tree: TemplateSectionEditModel[],
      treeIndex: number[]
    ): TemplateSectionEditModel {
      if (treeIndex.length > 1) {
        const newTreeIndex = [...treeIndex];
        const index = newTreeIndex.shift();
        const newTree = tree[index as number].subsections;
        return this.getSectionFromSectionNumber(newTree, newTreeIndex);
      } else {
        return tree[treeIndex[0]];
      }
    },

    getNextSectionOrder(tree: TemplateSectionEditModel[]) {
      if (tree.length === 0) return 0;
      const last = tree[tree.length - 1];
      return last.order + 1;
    },

    async refreshTemplateVersionAbbreviations() {
      if (!this.templateVersionIdInContext) return;

      const abbreviationsClient = new TemplateAbbreviationsClient();

      this.templateVersionAbbreviations = await abbreviationsClient.getAbbreviations(
        this.templateVersionIdInContext
      );
    },

    async refreshTemplateVersionVariables() {
      if (!this.templateVersionIdInContext) return;

      const variablesClient = new TemplateVariablesClient();

      this.templateVersionVariables = await variablesClient.getVariables(
        this.templateVersionIdInContext
      );
    },

    async selectTemplateVersion(templateVersionId: string) {
      this.templateVersionIdInContext = templateVersionId;

      // We are entering into a different version.
      // Clear any section in context.
      this.sectionIdInContext = undefined;

      this.templateVersionVariables = [];
      await this.refreshTemplateVersionVariables();
      this.templateVersionAbbreviations = [];
      await this.refreshTemplateVersionAbbreviations();
      await this.loadSections(templateVersionId);
    },

    async selectSection(sectionId: string) {
      this.sectionIdInContext = sectionId;
      const section = this.flattenedSectionDetails.find((x) => x.sectionId === sectionId);
      this.sectionVersionIdInContext = section?.version.templateSectionVersionId;
    },

    async createVersion(/* versionName: string */) {
      if (!this.templateId) return;

      const client = new TemplateEditorClient();
      await client.addVersion(this.templateId);
    },

    async publishVersion(templateVersionId: string | undefined) {
      if (!this.templateId) return;

      const client = new TemplateEditorClient();

      await client.publish(this.templateId, {
        templateVersionId: templateVersionId
      } as PublishTemplateVersionRequest);
    },

    async updateSection(sectionId: string, title: string, content: string) {
      const client = new TemplateEditorClient();

      const model = {
        title: title,
        content: content
      } as UpdateTemplateSectionRequest;

      await client.updateSection(sectionId, model);

      if (this.sectionIdInContext === sectionId) {
        this.sectionIdInContext = undefined;
      }
    },

    async updateTemplateOrder(templateSectionId: string, order: number) {
      const client = new TemplateEditorClient();

      await client.updateSectionOrder(templateSectionId, { order } as UpdateTemplateSectionOrderRequest);
    },

    findSection(tree: TemplateSectionEditModel[], id: string): TemplateSectionEditModel | undefined {
      for (const section of tree) {
        if (section.sectionId === id) return section;
        if (section.subsections) {
          const child = this.findSection(section.subsections, id);
          if (child) return child;
        }
      }
    },

    insertSection(tree: TemplateSectionEditModel[], section: TemplateSectionEditModel) {
      let insertIndex = 0;
      if (section.order !== 0) {
        insertIndex = tree.findIndex((x) => x.order == section.order);
      }
      if (insertIndex === -1) {
        tree.push(section);
      } else {
        for (let i = insertIndex; i < tree.length; i++) {
          const item = tree[i];
          item.order = item.order + 1;
        }
        tree.splice(insertIndex, 0, section);
      }

      //now reorder the sectionNumbers
      this.orderSections();
    },

    async receiveNewSection(model: TemplateSectionEditModel) {
      if (model.parentSectionId) {
        const parentSection = this.findSection(this.sectionDetailsTree, model.parentSectionId);
        if (!parentSection) return;
        this.insertSection(parentSection.subsections, model);
      } else {
        this.insertSection(this.sectionDetailsTree, model);
      }
      this.flattenSections();
      if (this.templateVersionIdInContext != null)
        await this.loadSectionHeadings(this.templateVersionIdInContext);
    },

    async receiveNewSectionVersion(model: TemplateSectionEditModel) {
      //find the section in the tree
      var section = this.findSection(this.sectionDetailsTree, model.sectionId);
      if (section) {
        section.version = model.version;
      }
      this.flattenSections();
      if (this.templateVersionIdInContext != null)
        await this.loadSectionHeadings(this.templateVersionIdInContext);
    },

    async receiveNewDocumentVersion(model: TemplateVersionHeadingModel) {
      if (!this.template) return;

      this.template?.versions.push;
      if (this.template.versions.some((x) => x.templateVersionId === model.templateVersionId)) {
        // We are already tracking this version locally.
        return;
      }

      this.template.versions.push(model);
    },

    receiveNewPublishedVersion(templateVersionId: string | undefined) {
      if (!this.template) return;

      this.template.publishedVersionId = templateVersionId;
    },

    receiveNewAbbreviation(model: TemplateAbbreviationModel) {
      this.templateVersionAbbreviations.push(model);
    },

    updateAbbreviation(model: TemplateAbbreviationModel) {
      const abbreviation = this.templateVersionAbbreviations.find((x) => x.id == model.id);

      if (!abbreviation) return;

      abbreviation.version.value = model.version.value;
    },

    receiveNewVariable(model: TemplateVariableModel) {
      this.templateVersionVariables.push(model);
    },

    updateVariable(model: TemplateVariableModel) {
      const variable = this.templateVersionVariables.find((x) => x.id == model.id);

      if (!variable) return;

      variable.version.value = model.version.value;
    },

    updateTemplateName(templateId: string, name: string) {
      if (!this.template) return;

      this.template.name = name;
    },

    updateTemplateSummary(templateId: string, summary: string) {
      if (!this.template) return;

      this.template.summary = summary;
    },

    updateTemplateVersionName(templateVersionId: string, name: string) {
      if (!this.template) return;

      const version = this.template.versions.find((x) => x.templateVersionId == templateVersionId);

      if (!version) return;

      version.versionName = name;
    },

    async refreshOrdering(templateId: string, model: TemplateSectionEditModel) {
      //remove this section from the tree
      this.removeSectionRecursive(this.sectionDetailsTree, model.sectionId);
      if (model.parentSectionId) {
        const parentSection = this.findSection(this.sectionDetailsTree, model.parentSectionId);
        if (!parentSection) return;
        this.insertSection(parentSection.subsections, model);
      } else {
        this.insertSection(this.sectionDetailsTree, model);
      }
      this.flattenSections();

      if (this.templateVersionIdInContext != null)
        await this.loadSectionHeadings(this.templateVersionIdInContext);
    },

    async deleteSection(sectionId: string) {
      const client = new TemplateEditorClient();

      await client.deleteSection(sectionId);

      if (this.sectionIdInContext === sectionId) {
        this.sectionIdInContext = undefined;
      }
    },

    async removeSection(templateVersionId: string, templateSectionId: string) {
      this.removeSectionRecursive(this.sectionDetailsTree, templateSectionId);
      this.orderSections();
      this.flattenSections();

      if (this.templateVersionIdInContext != null)
        await this.loadSectionHeadings(this.templateVersionIdInContext);
    },
    removeSectionRecursive(tree: TemplateSectionEditModel[], templateSectionId: string) {
      tree.forEach((section, index) => {
        if (section.sectionId === templateSectionId) {
          tree.splice(index, 1);
          return;
        }
        if (section.subsections) {
          this.removeSectionRecursive(section.subsections, templateSectionId);
        }
      });
    }
  }
});
