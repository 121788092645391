





















































import { defineComponent, ref, onMounted } from "@vue/composition-api";
export default defineComponent({
  name: "RenameTemplateVersionDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    templateVersionName: {
      type: String,
      required: true
    }
  },
  emits: ["close", "save"],
  setup(props, { emit, refs }) {
    const isRenameVersionFormValid = ref(false);
    const newTemplateVersionName = ref("");
    const savingTemplateVersionName = ref(false);
    const rules = [(v: string) => !!v || "Required"];
    const saveTemplateVersionNameClicked = () => {
      (refs.renameForm as HTMLFormElement).validate();
      if (!isRenameVersionFormValid) {
        return;
      }
      savingTemplateVersionName.value = true;
      emit("save", newTemplateVersionName.value);
      savingTemplateVersionName.value = false;
      (refs.renameForm as HTMLFormElement).reset();
    };
    function close() {
      (refs.renameForm as HTMLFormElement).reset();
      emit("close");
    }
    onMounted(() => {
      newTemplateVersionName.value = props.templateVersionName;
    });

    return {
      close,
      isRenameVersionFormValid,
      newTemplateVersionName,
      savingTemplateVersionName,
      saveTemplateVersionNameClicked,
      rules
    };
  },
  components: {}
});
