






































import { defineComponent, ref, onMounted } from "@vue/composition-api";
export default defineComponent({
  name: "ArchiveTemplateDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: ["close", "save"],
  setup(props, { emit, refs }) {
    const archivingTemplate = ref(false);

    const archiveTemplateClicked = () => {
      archivingTemplate.value = true;
      emit("save");
      archivingTemplate.value = false;
    };

    function close() {
      emit("close");
    }

    return {
      close,
      archivingTemplate,
      archiveTemplateClicked
    };
  },
  components: {}
});
