import { FontFamily } from "@/api/OtiumAppApi";

export function getFontFallbacks(fontFamily: FontFamily | undefined): string {
  switch (fontFamily) {
    case FontFamily.Arial:
      return 'Arial, Helvetica, sans-serif';
    case FontFamily.Verdana: //
      return 'Verdana, Arial, sans-serif';
    case FontFamily.Helvetica: //
      return 'Helvetica, "Trebuchet MS", sans-serif';
    case FontFamily.Tahoma:
      return 'Tahoma, Verdana, sans-serif';
    case FontFamily.Times:
      return '"Times New Roman", Times, serif';
    case FontFamily.Georgia:
      return 'Georgia, serif';
    case FontFamily.Garamond:
      return 'Garamond, serif';
    default:
      return 'Outfit, Arial, sans-serif';
  }
}
