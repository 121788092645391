import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import ReferenceWidget from './ReferenceWidget.vue'

export default Node.create({
  name: 'otiumReference',

  group: 'inline',
  inline: true,
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      name: {
        default: "test",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'otium-reference',

      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['otium-reference', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(ReferenceWidget)
  },
})