import _Vue from "vue";
import * as signalR from "@microsoft/signalr";
import {
  TemplateAbbreviationModel,
  TemplateVariableModel,
  TemplateSectionEditModel,
  TemplateSectionHeading,
  TemplateSectionVersionHeadingModel,
  TemplateVersionHeadingModel
} from "@/api/OtiumAppApi";
import { useUserStore } from "@/stores/userStore";
import { useTemplateEditorStore } from "@/stores/templateEditorStore";

const connection = new signalR.HubConnectionBuilder()
  .configureLogging(signalR.LogLevel.Information)
  .withUrl(`${process.env.VUE_APP_API_BASE_URL}/templateEditorHub`, {
    accessTokenFactory: () => {
      const userStore = useUserStore();
      return userStore.accessToken ?? "";
    }
  })
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: (retryContext) => {
      const reconnectDelay = Math.random() * 5000 + 2000;
      return reconnectDelay;
    }
  })
  .build();

connection.onreconnecting(function () {
  const templateStore = useTemplateEditorStore();
  templateStore.isHubConnected = false;
});

connection.onreconnected(function () {
  const templateStore = useTemplateEditorStore();
  templateStore.isHubConnected = true;

  // SignalR groups are lost on disconnect.
  // Rejoin the template.
  if (templateStore.templateId !== undefined) {
    templateEditorHub.joinTemplate(templateStore.templateId);
  }
});

/**
 * A section within a template has been updated.
 */
connection.on("TemplateSectionVersionCreated", async (templateId: string, model: any) => {
  const templateStore = useTemplateEditorStore();
  const editModel = TemplateSectionEditModel.fromJS(model);
  await templateStore.receiveNewSectionVersion(editModel);
});

/**
 * A new section has been added to a document.
 */
connection.on("TemplateSectionAdded", (templateId: string, model: any) => {
  const templateStore = useTemplateEditorStore();

  const editModel = TemplateSectionEditModel.fromJS(model);
  templateStore.receiveNewSection(editModel);
});

/**
 * A new version of the template has been created.
 */
connection.on("TemplateVersionCreated", (templateId: string, model: any) => {
  const templateStore = useTemplateEditorStore();

  const heading = TemplateVersionHeadingModel.fromJS(model);

  templateStore.receiveNewDocumentVersion(heading);
});

/**
 * The template has been renamed.
 */
connection.on("TemplateRenamed", (templateId: string, name: string) => {
  const templateStore = useTemplateEditorStore();

  templateStore.updateTemplateName(templateId, name);
});

/**
 * The template summary has been changed.
 */
connection.on("TemplateSummaryChanged", (templateId: string, summary: string) => {
  const templateStore = useTemplateEditorStore();

  templateStore.updateTemplateSummary(templateId, summary);
});

/**
 * The template version has been renamed.
 */
connection.on(
  "TemplateVersionRenamed",
  (templateId: string, templateVersionId: string, name: string) => {
    const templateStore = useTemplateEditorStore();

    templateStore.updateTemplateVersionName(templateVersionId, name);
  }
);

/**
 * The order of sections in a template version has changed.
 */
connection.on("TemplateSectionsReordered", async (templateId: string, model: any) => {
  const templateStore = useTemplateEditorStore();
  const editModel = TemplateSectionEditModel.fromJS(model);
  await templateStore.refreshOrdering(templateId, editModel);
});

/**
 * A section in a template version has been deleted.
 */
connection.on(
  "TemplateSectionDeleted",
  async (templateId: string, templateVersionId: string, templateSectionId: string) => {
    const templateStore = useTemplateEditorStore();

    await templateStore.removeSection(templateVersionId, templateSectionId);
  }
);

/**
 * A template version has been published.
 */
connection.on("TemplatePublished", async (templateId: string, templateVersionId: string | undefined) => {
  const templateStore = useTemplateEditorStore();

  await templateStore.receiveNewPublishedVersion(templateVersionId);
});

/**
 * A new variable has been added to a template.
 */
connection.on("TemplateVariableCreated", async (templateId: string, model: TemplateVariableModel) => {
  const templateStore = useTemplateEditorStore();
  await templateStore.receiveNewVariable(model);
});

/**
 * A variable has been updated.
 */
connection.on("TemplateVariableUpdated", async (projectId: string, model: TemplateVariableModel) => {
  const templateStore = useTemplateEditorStore();
  templateStore.updateVariable(model);
});

/**
 * A new abbreviation has been added to a template.
 */
connection.on(
  "TemplateAbbreviationCreated",
  async (templateId: string, model: TemplateAbbreviationModel) => {
    const templateStore = useTemplateEditorStore();
    await templateStore.receiveNewAbbreviation(model);
  }
);

/**
 * An abbreviation has been updated.
 */
connection.on(
  "TemplateAbbreviationUpdated",
  async (projectId: string, model: TemplateAbbreviationModel) => {
    const templateStore = useTemplateEditorStore();
    templateStore.updateAbbreviation(model);
  }
);

export interface TemplateEditorHub {
  connection: signalR.HubConnection;
  start(): Promise<void>;
  stop(): Promise<void>;
  joinTemplate(templateId: string): Promise<void>;
}

export const templateEditorHub = {
  connection: connection,

  async start() {
    try {
      const templateStore = useTemplateEditorStore();
      if (templateStore.isHubConnected) return;

      await connection.start();
      templateStore.isHubConnected = true;
    } catch (err) {
      console.error(err);
      setTimeout(this.start, 5000);
    }
  },

  async stop() {
    await connection.stop();
  },

  async joinTemplate(templateId: string) {
    await connection.send("ConnectToTemplate", templateId);
  }
} as TemplateEditorHub;

export function TemplateEditorHub(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$templateEditorHub = templateEditorHub;
}
