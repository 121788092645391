




































































import { defineComponent, ref, PropType, watch, toRefs } from "@vue/composition-api";
import Vue from "vue";
import { TableData } from "./tableDataConversion";
import VariableEditor from "@/components/Editor/VariableEditor.vue";

export default defineComponent({
  name: "TableEditorDialog",
  components: { VariableEditor },
  model: {
    prop: "data",
    event: "save"
  },
  props: {
    isDocument: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object as PropType<TableData | null>,
      default: null
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: ["save", "delete", "cancel"],
  setup(props, { emit }) {
    const { data } = toRefs(props);

    function getTableData(): TableData {
      return props.data != null
        ? Object.assign({}, props.data)
        : {
            title: "",
            footnotes: "",
            data: ""
          };
    }
    const tableData = ref<TableData>(getTableData());
    const isCreating = ref(data.value == null);

    watch(data, () => {
      tableData.value = getTableData();
      isCreating.value = data.value == null;
    });

    function save() {
      emit("save", tableData.value);
    }
    function cancel() {
      tableData.value = getTableData();
      emit("cancel");
    }

    return {
      tableData,
      isCreating,
      save,
      cancel
    };
  }
});
