



















































































import {
  CreateTemplateVariableModel,
  TemplateVariableModel,
  TemplateVariablesClient,
  UpdateTemplateVariableValueModel
} from "@/api/OtiumAppApi";
import { useEditorStore } from "@/stores/editorStore";
import { useTemplateEditorStore } from "@/stores/templateEditorStore";
import { defineComponent, ref, watch } from "@vue/composition-api";
import VariableEditor from "../Editor/VariableEditor.vue";

export default defineComponent({
  components: { VariableEditor },
  props: {
    openedDialogs: {
      type: Object,
      required: true
    },
    editingVariable: {
      type: TemplateVariableModel
    }
  },
  setup(props, context) {
    const templateStore = useTemplateEditorStore();
    const editVariableValue = ref("");
    watch(
      () => props.editingVariable,
      (newValue) => {
        if (newValue) {
          editVariableValue.value = newValue.version.value;
        }
      }
    );

    const newVariableValue = ref("");
    const newVariableName = ref("");

    const savingNewVariable = ref(false);
    const saveNewVariable = async () => {
      if (!templateStore.templateVersionIdInContext) return;
      savingNewVariable.value = true;

      const client = new TemplateVariablesClient();
      await client.createVariable(templateStore.templateVersionIdInContext, {
        value: newVariableValue.value,
        name: newVariableName.value
      } as CreateTemplateVariableModel);

      const editorStore = useEditorStore();
      editorStore.insertVariable(newVariableName.value);

      savingNewVariable.value = false;
      props.openedDialogs.add = false;
      newVariableValue.value = "";
      newVariableName.value = "";
    };

    const savingEditVariable = ref(false);
    const saveEditVariable = async () => {
      if (!props.editingVariable) return;
      savingEditVariable.value = true;
      const client = new TemplateVariablesClient();
      await client.updateVariable(props.editingVariable.id, {
        value: editVariableValue.value
      } as UpdateTemplateVariableValueModel);

      savingEditVariable.value = false;
      props.openedDialogs.edit = false;
    };

    return {
      templateStore,
      editVariableValue,
      savingEditVariable,
      saveEditVariable,

      newVariableName,
      newVariableValue,
      savingNewVariable,
      saveNewVariable
    };
  }
});
