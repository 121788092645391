





















































import { defineComponent, ref, onMounted } from "@vue/composition-api";
export default defineComponent({
  name: "TemplateSummaryDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    templateSummary: {
      type: String,
      required: true
    }
  },
  emits: ["close", "save"],
  setup(props, { emit, refs }) {
    const isTemplateSummaryFormValid = ref(false);
    const newTemplateSummary = ref("");
    const savingTemplateSummary = ref(false);
    const rules = [(v: string) => !!v || "Required"];
    const saveTemplateSummaryClicked = () => {
      (refs.summaryForm as HTMLFormElement).validate();
      if (!isTemplateSummaryFormValid) {
        return;
      }
      savingTemplateSummary.value = true;
      emit("save", newTemplateSummary.value);
      savingTemplateSummary.value = false;
      (refs.summaryForm as HTMLFormElement).reset();
    };
    function close() {
      (refs.summaryForm as HTMLFormElement).reset();
      emit("close");
    }
    onMounted(() => {
      newTemplateSummary.value = props.templateSummary;
    });

    return {
      close,
      isTemplateSummaryFormValid,
      newTemplateSummary,
      savingTemplateSummary,
      saveTemplateSummaryClicked,
      rules
    };
  },
  components: {}
});
