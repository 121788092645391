


































import { computed, defineComponent, onMounted, ref, toRefs, watch } from "@vue/composition-api";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";
import NodeProps from "../NodeProps";
import { Chart, registerables } from "chart.js";
import { convertToChartjs } from "./chartDataConversion";
import Vue from "vue";

Chart.register(...registerables);

export default defineComponent({
  name: "OtiumChart",
  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  props: NodeProps,

  setup(props) {
    const { node, editor } = toRefs(props);

    const title = node?.value?.attrs?.title;
    const spanId = node?.value?.attrs?.chartid;

    const chartId = "otium_chart_" + Math.random().toString(36).substring(2);

    function getChartData() {
      try {
        return convertToChartjs(JSON.parse(node?.value?.attrs.data));
      } catch (e) {
        return null;
      }
    }

    const editable = computed(() => {
      return editor?.value?.options.editable ?? false;
    });

    if (node != null)
      watch(node, () => {
        renderChart();
      });

    const hovered = ref(false);

    let canvasContext = null as CanvasRenderingContext2D | null;
    let chart = null as Chart | null;

    function renderChart() {
      if (canvasContext == null)
        canvasContext =
          (document.getElementById(chartId) as HTMLCanvasElement | null)?.getContext("2d") ?? null;
      if (chart != null) chart.destroy();
      if (canvasContext != null) chart = new Chart(canvasContext, getChartData());
    }

    onMounted(() => Vue.nextTick(() => renderChart()));

    function openEditor() {
      if (!editable.value) return;
      Vue.nextTick(() => {
        if (props.getPos != undefined) editor?.value?.commands.setNodeSelection(props.getPos());
        const el = document.getElementById("editor_add_edit_chart_button") as HTMLButtonElement | null;
        if (el != null) el.click();
      });
    }

    return {
      chartId,
      editable,
      hovered,
      openEditor,
      title,
      spanId
    };
  }
});
