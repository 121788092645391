import { Editor } from "@tiptap/vue-2";
import { defineStore } from "pinia";
import { Level } from "@tiptap/extension-heading";
import { TableData } from "@/components/Editor/widgets/Tables/tableDataConversion";

export const useEditorStore = defineStore("editor", {
  state: () => ({
    editor: null as Editor | null,
    contentScale: 1
  }),
  getters: {
    hasEditor: (state): boolean => state.editor !== null,
    hasTableSelected: (state): boolean => state.editor?.isActive("table") ?? false,
    isFontSerif: (state): boolean =>
      state.editor?.isActive("textStyle", { fontFamily: "serif" }) ?? false,
    isFontSansSerif: (state): boolean =>
      state.editor?.isActive("textStyle", { fontFamily: "sans-serif" }) ?? false,
    isTextTransformUppercase: (state): boolean =>
      state.editor?.isActive("textStyle", { textTransform: "uppercase" }) ?? false,
    isTextTransformLowercase: (state): boolean =>
      state.editor?.isActive("textStyle", { textTransform: "lowercase" }) ?? false,
    isTextTransformCapitalize: (state): boolean =>
      state.editor?.isActive("textStyle", { textTransform: "capitalize" }) ?? false,
    isHeadingLevel: (state) => (level: number) =>
      state.editor?.isActive("heading", { level: level }) ?? false,
    isParagraph: (state): boolean => state.editor?.isActive("paragraph") ?? false,
    isBulletList: (state): boolean => state.editor?.isActive("bulletList") ?? false,
    isOrderedList: (state): boolean => state.editor?.isActive("orderedList") ?? false,
    isTextBold: (state): boolean => state.editor?.isActive("bold") ?? false,
    isTextItalic: (state): boolean => state.editor?.isActive("italic") ?? false,
    isTextUnderline: (state): boolean => state.editor?.isActive("underline") ?? false,
    isTextStrike: (state): boolean => state.editor?.isActive("strike") ?? false,
    isTextAlignLeft: (state): boolean => state.editor?.isActive({ textAlign: "left" }) ?? false,
    isTextAlignCenter: (state): boolean => state.editor?.isActive({ textAlign: "center" }) ?? false,
    isTextAlignRight: (state): boolean => state.editor?.isActive({ textAlign: "right" }) ?? false,
    isTextAlignJustify: (state): boolean => state.editor?.isActive({ textAlign: "justify" }) ?? false
  },
  actions: {
    // Zoom
    increaseZoom() {
      this.contentScale *= 1.2;
    },
    decreaseZoom() {
      this.contentScale *= 0.8;
      if (this.contentScale < 1) this.contentScale = 1;
    },

    // Table
    insertTable() {
      this.editor?.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
    },
    addColumnBefore() {
      this.editor?.chain().focus().addColumnBefore().run();
    },
    addColumnAfter() {
      this.editor?.chain().focus().addColumnAfter().run();
    },
    deleteColumn() {
      this.editor?.chain().focus().deleteColumn().run();
    },
    addRowBefore() {
      this.editor?.chain().focus().addRowBefore().run();
    },
    addRowAfter() {
      this.editor?.chain().focus().addRowAfter().run();
    },
    deleteRow() {
      this.editor?.chain().focus().deleteRow().run();
    },
    mergeCells() {
      this.editor?.chain().focus().mergeCells().run();
    },
    splitCell() {
      this.editor?.chain().focus().splitCell().run();
    },
    deleteTable() {
      this.editor?.chain().focus().deleteTable().run();
    },

    // Font color
    setFontColor(fontColor: string) {
      this.editor?.chain().focus().setColor(fontColor).run();
    },

    // Font Family
    setFontFamily(fontFamily: string) {
      this.editor?.chain().focus().setFontFamily(fontFamily).run();
    },
    unsetFontFamily() {
      this.editor?.chain().focus().unsetFontFamily().run();
    },

    // Text Transform
    setTextTransform(caseStyle: string) {
      this.editor?.chain().focus().setTextTransform(caseStyle).run();
    },
    unsetTextTransform() {
      this.editor?.chain().focus().unsetTextTransform().run();
    },

    // Heading
    setHeading(headingLevel: number) {
      this.editor
        ?.chain()
        .focus()
        .setHeading({ level: headingLevel as Level })
        .run();
    },
    setParagraph() {
      this.editor?.chain().focus().setParagraph().run();
    },

    // Text Styles
    toggleBold() {
      this.editor?.chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor?.chain().focus().toggleItalic().run();
    },
    toggleUnderline() {
      this.editor?.chain().focus().toggleUnderline().run();
    },
    toggleStrike() {
      this.editor?.chain().focus().toggleStrike().run();
    },

    // Lists
    toggleBulletList() {
      this.editor?.chain().focus().toggleBulletList().run();
    },
    toggleOrderedList() {
      this.editor?.chain().focus().toggleOrderedList().run();
    },

    // Alignment
    setTextAlign(alignment: string) {
      this.editor?.chain().focus().setTextAlign(alignment).run();
    },

    // Images
    insertImage(image: File) {
      if (this.editor === null) return;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        this.editor
          ?.chain()
          .focus()
          .setImage({ src: reader.result as string })
          .run();
      };
    },

    // Links
    insertSectionLink(sectionId: string, value: string) {
      if (this.editor == null) return;
      this.editor
        .chain()
        .focus()
        .insertContent(
          `<otium-link name="${value}" reference="${sectionId}" type="section"></otium-link>`
        )
        .run();
    },

    //Abbreviations
    insertAbbreviation(name: string) {
      if (this.editor === null) return;
      const abbreviationTag = `<otium-abbreviation name="${name}"></otium-abbreviation>`;
      this.editor.chain().focus().insertContent(abbreviationTag).run();
    },

    //Verbose Abbreviation
    insertVerboseAbbreviation(name: string) {
      if (this.editor === null) return;
      const abbreviationTag = `<otium-verbose-abbreviation name="${name}"></otium-verbose-abbreviation>`;
      this.editor.chain().focus().insertContent(abbreviationTag).run();
    },

    // Variables
    insertVariable(name: string) {
      if (this.editor === null) return;
      const variableTag = `<otium-variable name="${name}"></otium-variable>`;
      this.editor.chain().focus().insertContent(variableTag).run();
    },

    // References
    insertReference(name: string) {
      if (this.editor === null) return;
      const referenceTag = `<otium-reference name="${name}"></otium-variable>`;
      this.editor.chain().focus().insertContent(referenceTag).run();
    },

    // Charts
    insertChart(chart: string) {
      this.editor?.chain().focus().insertContent(chart).run();
    },
    updateChart(chart: string, image: string) {
      this.editor?.chain().focus().updateAttributes("otiumChart", { data: chart, image: image }).run();
    },

    // Tables
    insertOtiumTable(table: string) {
      this.editor?.chain().focus().insertContent(table).run();
    },
    updateOtiumTable(tableData: TableData) {
      this.editor
        ?.chain()
        .focus()
        .updateAttributes("otiumTable", {
          data: tableData.data,
          title: tableData.title,
          footnotes: tableData.footnotes
        })
        .run();
    }
  }
});
