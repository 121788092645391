export enum ChartType {
  Bar = "bar",
  StackedBar = "stackedbar",
  Line = "line",
  Pie = "pie"
}
export interface ChartDataset {
  name: string; // the dataset name (displayed in the legend)
  data: Array<number | string>; // the array of the datapoints for this dataset
  color: string | Array<string>; // the color for this dataset (or each of the points if an array)
}
export interface ChartData {
  type: ChartType; // the type of the chart
  title: string; // the chart title (displayed at top)
  description: string | null; // the chart description (displayed below title)
  labels: Array<string>; // the labels for each datum in each dataset
  datasets: Array<ChartDataset>; // each dataset
}

export function convertToChartjs(data: ChartData): any {
  const chart: any = {
    type: undefined as string | undefined,
    data: {
      labels: data.labels,
      datasets: data.datasets.map((dataset) => ({
        label: dataset.name,
        data: dataset.data,
        backgroundColor: dataset.color
      }))
    },
    options: {
      animation: false,
      plugins: {
        title: {
          text: data.title,
          display: true
        },
        subtitle: {
          text: data.description ?? "",
          display: data.description != null && data.description != ""
        }
      },
      responsive: true,
      maintainAspectRatio: false
    }
  };

  switch (data.type) {
    case ChartType.Bar:
      chart.type = "bar";
      chart.options.scales = {
        y: {
          beginAtZero: true
        }
      };
      break;

    case ChartType.StackedBar:
      chart.type = "bar";
      chart.options.scales = {
        x: {
          stacked: true
        },
        y: {
          beginAtZero: true,
          stacked: true
        }
      };
      break;

    case ChartType.Line:
      chart.type = "line";

      break;

    case ChartType.Pie:
      chart.type = "pie";

      break;
  }

  return chart;
}
