import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ChartWidget from "./ChartWidget.vue";

export default Node.create({
  name: "otiumChart",

  group: "block",
  inline: false,
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      data: {
        default: ""
      },
      image: {
        default: ""
      },
      chartid: {
        default: ""
      },
      title: {
        default: ""
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "otium-chart"
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["otium-chart", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(ChartWidget);
  }
});
