import { Mark, mergeAttributes } from "@tiptap/core";

const Span = Mark.create({
  name: "ins",

  addOptions() {
    return {
      HTMLAttributes: {
        style: "background-color: lightgreen; text-decoration: none"
      }
    };
  },

  parseHTML() {
    return [{ tag: "ins" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["ins", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  }
});

export default Span;
