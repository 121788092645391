























































































































































































































import { defineComponent, ref, PropType, watch, toRefs } from "@vue/composition-api";
import Vue from "vue";
import { Chart } from "chart.js";
import { ChartData, ChartDataset, ChartType, convertToChartjs } from "./chartDataConversion";
import ChartDataEditorColorPicker from "./ChartDataEditorColorPicker.vue";

export default defineComponent({
  name: "ChartDataEditorDialog",
  components: {
    ChartDataEditorColorPicker
  },
  model: {
    prop: "data",
    event: "save"
  },
  props: {
    data: {
      type: Object as PropType<ChartData | null>,
      default: null
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  emits: ["save", "delete", "cancel"],
  setup(props, { emit, refs }) {
    const defaultColor = "#0388A6FF";
    const defaultLabel = "";
    const defaultDatasetName = "";
    const defaultValue = "";
    const requiredRule = ref((v: string) => (v != undefined && v.trim() == "" ? "Required" : true));
    const { data } = toRefs(props);
    const isChartFormValid = ref(false);

    function getChartData(): ChartData {
      return props.data != null
        ? Object.assign({}, props.data)
        : {
            type: ChartType.Bar,
            title: "",
            description: "",
            labels: [""],
            datasets: [
              {
                name: "",
                data: [""],
                color: defaultColor
              }
            ]
          };
    }
    const chartData = ref<ChartData>(getChartData());
    const isCreating = ref(data.value == null);

    watch(data, () => {
      chartData.value = getChartData();
      isCreating.value = data.value == null;
    });

    const chartTypes = [
      { text: "Bar", value: ChartType.Bar },
      { text: "Stacked Bar", value: ChartType.StackedBar },
      { text: "Line", value: ChartType.Line },
      { text: "Pie", value: ChartType.Pie }
    ];

    function insertDataset(index: number) {
      const data = chartData.value as ChartData;
      const dataset: ChartDataset = {
        name: defaultDatasetName,
        color: defaultColor,
        data: Array(data.labels.length).fill(defaultValue)
      };
      data.datasets.splice(index, 0, dataset);
    }
    function deleteDataset(index: number) {
      (chartData.value as ChartData).datasets.splice(index, 1);
    }
    function insertRecord(index: number) {
      const data = chartData.value as ChartData;
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].data.splice(index, 0, defaultValue);
        if (isArray(data.datasets[i].color))
          (data.datasets[i].color as string[]).splice(index, 0, defaultColor);
      }
      data.labels.splice(index, 0, defaultLabel);
    }
    function deleteRecord(index: number) {
      const data = chartData.value as ChartData;
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].data.splice(index, 1);
        if (isArray(data.datasets[i].color)) (data.datasets[i].color as string[]).splice(index, 1);
      }
      data.labels.splice(index, 1);
    }
    function switchDatasetColor(index: number, multiple: boolean) {
      const data = chartData.value as ChartData;
      if (multiple) {
        data.datasets[index].color = [];
        for (let i = 0; i < data.datasets[index].data.length; i++) {
          (data.datasets[index].color as string[]).push(defaultColor);
        }
      } else {
        Vue.set(data.datasets[index], "color", defaultColor);
      }
    }

    const previewingChart = ref(false);
    const chartCanvasElementId = "otium_chart_" + Math.random().toString(36).substring(2);
    let previewChart = null as Chart | null;
    function showChartPreview() {
      previewingChart.value = true;
      Vue.nextTick(() => {
        const chartjsData = convertToChartjs(chartData.value);
        const ctx = (
          document.getElementById(chartCanvasElementId) as HTMLCanvasElement | null
        )?.getContext("2d");
        if (ctx != null) previewChart = new Chart(ctx, chartjsData);
      });
    }
    function closeChartPreview() {
      previewingChart.value = false;
      if (previewChart != null) previewChart.destroy();
    }

    function save() {
      (refs.chartInformationForm as any).validate();
      if (!isChartFormValid.value) return;

      emit("save", chartData.value);
    }
    function cancel() {
      chartData.value = getChartData();
      emit("cancel");
    }

    function isArray(item: any) {
      return Array.isArray(item);
    }

    return {
      requiredRule,
      chartData,
      isCreating,
      chartTypes,
      isChartFormValid,

      insertDataset,
      deleteDataset,
      insertRecord,
      deleteRecord,
      switchDatasetColor,

      previewingChart,
      chartCanvasElementId,
      showChartPreview,
      closeChartPreview,

      save,
      cancel,
      isArray
    };
  }
});
