





import { useProjectStore } from "@/stores/projectStore";
import { computed, defineComponent } from "@vue/composition-api";
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2'
import NodeProps from '../NodeProps'
export default defineComponent({
    components: {
        NodeViewWrapper,
        NodeViewContent
    },
    props: NodeProps,


    setup(props) {
        const store = useProjectStore();

        const variableName = computed(() => {
            return props.node?.attrs.name.toString();
        })

        const variable = computed(() => {
            return store.documentVersionVariables.find(x => x.name === variableName.value);
        });

        const value = computed(() => {
            return variable.value?.version.value ?? "";
        })

        return {
            store,
            value,
            variableName,
            variable,
        }
    },
});
