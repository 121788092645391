





import { useProjectStore } from "@/stores/projectStore";
import { computed, defineComponent } from "@vue/composition-api";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";
import NodeProps from "../NodeProps";
export default defineComponent({
  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  props: NodeProps,

  setup(props) {
    const store = useProjectStore();

    const referenceName = computed(() => {
      return props.node?.attrs.name.toString();
    });

    return {
      store,
      referenceName
    };
  }
});
