var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-12",attrs:{"elevation":"3"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"remove-border mr-8",attrs:{"placeholder":"Enter section title"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","tile":"","outlined":"","large":"","loading":_vm.saving},on:{"click":_vm.save}},[_vm._v(" Save changes "),_c('v-img',{staticClass:"ml-3",staticStyle:{"width":"10px","height":"7px","object-fit":"contain"},attrs:{"src":require("@/assets/icons/check.png"),"alt":""}})],1),_c('v-dialog',{attrs:{"max-width":"460px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","tile":"","large":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v("Cancel")])]}}]),model:{value:(_vm.confirmDiscard),callback:function ($$v) {_vm.confirmDiscard=$$v},expression:"confirmDiscard"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Confirm cancel")]),_c('v-card-text',[_vm._v("You are about to discard your changes. Any changes you have made will be permanently lost.")]),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","outlined":"","tile":"","large":""},on:{"click":_vm.discard}},[_vm._v(" I understand, discard changes "),_c('v-img',{staticClass:"ml-3",staticStyle:{"width":"10px","height":"7px","object-fit":"contain"},attrs:{"src":require("@/assets/icons/check.png"),"alt":""}})],1),_c('v-btn',{attrs:{"color":"grey","tile":"","large":"","elevation":"0"},on:{"click":function($event){_vm.confirmDiscard = false}}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)],1)],1),_c('Editor',{attrs:{"isDocument":false},model:{value:(_vm.contents),callback:function ($$v) {_vm.contents=$$v},expression:"contents"}})],1),_c('v-card-actions',[_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","tile":"","large":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v("Re-order")])]}}]),model:{value:(_vm.showMoveDialog),callback:function ($$v) {_vm.showMoveDialog=$$v},expression:"showMoveDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Re-order section")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_vm._v("Select where you would like to insert this section above")])],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.sectionsForReorder,"outlined":"","hide-details":""},model:{value:(_vm.newSectionOrder),callback:function ($$v) {_vm.newSectionOrder=$$v},expression:"newSectionOrder"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","outlined":"","tile":"","large":"","loading":_vm.reorderingSection},on:{"click":_vm.reorderSection}},[_vm._v(" Move section "),_c('img',{staticClass:"ml-3",staticStyle:{"width":"10px","height":"7px","object-fit":"contain"},attrs:{"src":require("@/assets/icons/check.png"),"alt":""}})]),_c('v-btn',{attrs:{"color":"grey","tile":"","large":"","elevation":"0"},on:{"click":function($event){_vm.showMoveDialog = false}}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"460px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-4",attrs:{"color":"grey","tile":"","large":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v("Delete section")])]}}]),model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Confirm delete")]),_c('v-card-text',[_vm._v("You are about to delete this section. Any changes and previous versions you have made will be permanently lost.")]),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary","outlined":"","tile":"","large":""},on:{"click":_vm.deleteSection}},[_vm._v(" I understand, delete section "),_c('v-img',{staticClass:"ml-3",staticStyle:{"width":"10px","height":"7px","object-fit":"contain"},attrs:{"src":require("@/assets/icons/check.png"),"alt":""}})],1),_c('v-btn',{attrs:{"color":"grey","tile":"","large":"","elevation":"0"},on:{"click":function($event){_vm.confirmDelete = false}}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }