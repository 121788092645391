






import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { defineComponent } from "@vue/composition-api";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import TextColor from "@tiptap/extension-color";
import ProjectNodeCreate from "./widgets/ProjectVariable/Extensions";
import TemplateNodeCreate from "./widgets/TemplateVariable/Extensions";
import InsertedNode from "./widgets/InsertedNode";
import {
  ProjectAbbreviation,
  ProjectVerboseAbbreviation
} from "./widgets/ProjectAbbreviation/Extensions";
import {
  TemplateAbbreviation,
  TemplateVerboseAbbreviation
} from "./widgets/TemplateAbbreviation/Extensions";
import ProjectChartNodeCreate from "./widgets/ProjectChart/Extensions";
import ReferenceNodeCreate from "./widgets/DocumentReference/Extensions";
import TextTransform from "./widgets/TextTransform";
import SectionLink from "./widgets/DocumentSectionLink/Extension";
import OtiumTable from "@/components/Editor/widgets/Tables/Extension";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    isDocument: {
      type: Boolean,
      default: true
    }
  },
  components: {
    EditorContent
  },

  data() {
    return {
      editor: null as Editor | null
    };
  },

  mounted() {
    this.editor = new Editor({
      content: this.value || "",
      editable: false,

      extensions: [
        InsertedNode,
        StarterKit,
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell,
        Underline,
        Image.configure({
          allowBase64: true
        }),
        TextAlign.configure({
          types: ["heading", "paragraph"]
        }),
        TextStyle,
        FontFamily,
        TextTransform,
        this.isDocument ? ProjectNodeCreate : TemplateNodeCreate,
        this.isDocument ? ProjectAbbreviation : TemplateAbbreviation,
        this.isDocument ? ProjectVerboseAbbreviation : TemplateVerboseAbbreviation,

        ProjectChartNodeCreate,
        ReferenceNodeCreate,
        TextColor,
        SectionLink,
        OtiumTable
      ]
    });
  },

  watch: {
    value(newValue, oldValue) {
      if (!this.editor) return;

      const isSame = this.editor.getHTML() === newValue;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(newValue, false);
    }
  },

  beforeDestroy() {
    if (this.editor == null) return;

    this.editor.destroy();
  }
});
