

























































import { computed, defineComponent, ref } from "@vue/composition-api";
import { useTemplateEditorStore } from "@/stores/templateEditorStore";
import { useUserStore } from "@/stores/userStore";
import { TemplateEditorClient } from "@/api/OtiumAppApi";
export default defineComponent({
  name: "AddSectionDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    templateVersionId: {
      type: String,
      required: true
    },
    newSectionOrder: {
      type: Number,
      required: true
    },
    newSectionParentSectionId: {
      type: String
    },
    newSectionParentSectionName: {
      type: String
    }
  },
  emits: ["close"],
  setup(props, { emit, refs }) {
    const templateStore = useTemplateEditorStore();
    const userStore = useUserStore();
    const isCreateSectionFormValid = ref(false);
    const addingSection = ref(false);
    const newSectionTitle = ref("");

    const addSection = async () => {
      validateForm();
      if (!isCreateSectionFormValid.value) return;
      addingSection.value = true;
      const client = new TemplateEditorClient();
      try {
        await client.addSection(
          props.templateVersionId,
          newSectionTitle.value,
          props.newSectionOrder,
          props.newSectionParentSectionId
        );
        close();
      } catch {
        addingSection.value = false;
      }
    };
    const requiredRule = (v: string | null) => {
      return v != null && v.trim() != "" ? true : "Required";
    };
    function validateForm() {
      (refs.createSectionForm as HTMLFormElement).validate();
      return isCreateSectionFormValid.value;
    }

    function close() {
      (refs.createSectionForm as HTMLFormElement).reset();
      emit("close");
    }

    return {
      close,
      addSection,
      isCreateSectionFormValid,
      requiredRule,
      newSectionTitle,
      addingSection,
      templateStore,
      userStore
    };
  },
  components: {}
});
