import {
  CreateDocumentModel,
  CreateSectionModel,
  DocumentHeadingModel,
  DocumentsClient,
  DocumentAbbreviationModel,
  DocumentAbbreviationsClient,
  DocumentReferenceModel,
  DocumentReferenceClient,
  DocumentVariableModel,
  DocumentVariablesClient,
  DocumentVersionHeadingModel,
  DocumentVersionStatus,
  ProjectsClient,
  SaveReasonModel,
  SectionDetails,
  SectionHeading,
  SectionCommentModel,
  SectionVersionHeading,
  UpdateSectionModel,
  UpdateSectionOrderRequest,
  ICreateSectionModel,
  DocumentSectionCommentModel,
  FontFamily,
  DocumentSectionReviewClient,
  CreateReviewModel,
  ICreateReviewModel,
  SectionReviewModel,
  DocumentSectionReviewModel,
  AcceptReviewWithChangesModel,
  IAcceptReviewWithChangesModel,
  IDocumentSectionReviewModel
} from "@/api/OtiumAppApi";
import { getFontFallbacks } from "@/constants/fonts";
import { defineStore } from "pinia";
import Vue from "vue";

export type DocumentSections = {
  document: DocumentHeadingModel;
  sections: SectionHeading[];
};

export type DocumentTree = {
  id: string;
  name: string;
  order: number;
  type: DocumentPartType;
  documentVersionId: string;
  children: DocumentTree[] | undefined;
};

export enum DocumentPartType {
  Document,
  DocumentVersion,
  Section,
  SectionVersion
}

const MAX_SUBSECTIONS = 10;

export const useProjectStore = defineStore({
  id: "project",
  state: () => ({
    /**
     * The id of the project being edited.
     */
    projectId: undefined as string | undefined,

    projectName: "",

    projectOrganization: "",

    /**
     * The id of the document the user has open.
     */
    documentIdInContext: undefined as string | undefined,
    documentVersionIdInContext: undefined as string | undefined,

    /**
     * The id of the section the user has selected to edit.
     */
    sectionIdInContext: undefined as string | undefined,
    sectionVersionIdInContext: undefined as string | undefined,
    /**
     * The top level summarized information of a document within a project.
     */
    documents: [] as DocumentHeadingModel[],

    /**
     * The sections loaded for each document.
     */
    sectionHeadings: {} as { [documentVersionId: string]: SectionHeading[] },

    /**
     * Holds the contents of sections for the document version id in context.
     */
    sectionDetailsTree: [] as SectionDetails[],

    /**
     * Holds the flattened sections
     */
    flattenedSectionDetails: [] as SectionDetails[],

    /**
     * The abbreviations for the document version in context.
     */
    documentVersionAbbreviations: [] as DocumentAbbreviationModel[],

    /**
     * The variables for the document version in context.
     */
    documentVersionVariables: [] as DocumentVariableModel[],

    /**
     * The references for the document version in context.
     */
    documentVersionReferences: [] as DocumentReferenceModel[],

    /**
     * The font family for the document version in context.
     */
    documentVersionFontFamily: undefined as FontFamily | undefined,

    /**
     * If we are connected to the editor hub for live updates.
     */
    isHubConnected: false,

    saveReasons: [] as SaveReasonModel[]
  }),

  getters: {
    hasDocumentVersionInContext: (state) => state.documentVersionIdInContext !== undefined,
    hasSectionInContext: (state) => state.sectionIdInContext !== undefined,

    allSections: (state) => {
      const sections = [];
      for (const documentId in state.sectionHeadings) {
        sections.push(...state.sectionHeadings[documentId]);
      }

      return sections as SectionHeading[];
    },

    sectionDetailsInContext: (state) => {
      if (!state.sectionIdInContext) return undefined;

      return state.flattenedSectionDetails.find((x) => x.id === state.sectionIdInContext);
    },

    sectionInContext(): SectionHeading | undefined {
      if (!this.sectionIdInContext) return undefined;

      return this.allSections.find((x) => x.sectionId === this.sectionIdInContext);
    },

    getSectionDetailsById: (state) => {
      return (sectionId: string) => {
        return state.flattenedSectionDetails.find((x) => x.id === sectionId);
      };
    },

    documentInContext: (state) =>
      state.documents.find((x) => x.documentId === state.documentIdInContext),
    documentVersionInContext: (state) =>
      state.documents
        .flatMap((x) => x.versions)
        .find((x) => x.documentVersionId === state.documentVersionIdInContext),

    documentName(): string {
      return this.documentInContext?.name ?? "";
    },

    documentVersionFontWithFallbacks(): string | undefined {
      if (!this.documentVersionFontFamily) return;
      return getFontFallbacks(this.documentVersionFontFamily);
    },

    /**
     * Builds a tree hierarchy of documents, Versions, and Sections when the sections are loaded.
     * @returns
     */
    documentTree(): DocumentTree[] {
      function getSubsectionsRecursive(
        sectionHeading: SectionHeading,
        sectionNumbers: number[],
        maxDepth: number
      ): DocumentTree[] | undefined {
        if (maxDepth <= 0) return undefined;

        const tree = sectionHeading.subsections.map((subsection, index): DocumentTree => {
          const newSectionNumbers = [...sectionNumbers, index + 1];
          return {
            id: subsection.sectionId,
            documentVersionId: subsection.documentVersionId,
            order: subsection.order,
            name: `${newSectionNumbers.join(".")}. ${subsection.currentVersion.title}`,
            type: DocumentPartType.Section,
            children: getSubsectionsRecursive(subsection, newSectionNumbers, maxDepth - 1)
          };
        });

        if (tree.length < 1) return undefined;
        else return tree;
      }

      if (!this.documents) return [];

      // Documents
      return this.documents.map((d) => {
        return {
          id: d.documentId,
          name: d.name,
          type: DocumentPartType.Document,
          // Versions
          children: d.versions
            .sort((a, b) =>
              a.versionNumber < b.versionNumber ? 1 : a.versionNumber > b.versionNumber ? -1 : 0
            )
            .map((v) => {
              return {
                id: v.documentVersionId,
                name: v.versionName,
                order: v.versionNumber,
                type: DocumentPartType.DocumentVersion,

                // Sections
                children: this.sectionHeadings[v.documentVersionId]
                  ? this.sectionHeadings[v.documentVersionId]
                      .sort((a, b) => a.order - b.order)
                      .map((s, index) => {
                        return {
                          id: s.sectionId,
                          order: s.order,
                          name: `${index + 1}. ${s.currentVersion.title}`,
                          documentVersionId: s.documentVersionId,
                          type: DocumentPartType.Section,
                          children: getSubsectionsRecursive(s, [index + 1], MAX_SUBSECTIONS)
                        };
                      })
                  : []
              } as DocumentTree;
            })
        } as DocumentTree;
      });
    },

    documentsWithSections(): DocumentSections[] {
      const self = this;

      return this.documents.map((document: DocumentHeadingModel) => {
        document.versions;
        let sections = self.sectionHeadings[document.documentId];

        if (sections === undefined) sections = [];

        return {
          document: document,
          sections: [...sections].sort((a, b) => {
            return b.order - a.order;
          })
        } as DocumentSections;
      });
    }
  },

  actions: {
    async loadProject(projectId: string) {
      this.projectId = projectId;

      await this.refreshProject();
    },

    /**
     * Refresh the entire state of a project.
     * @returns
     */
    async refreshProject() {
      if (!this.projectId) return;

      const projectClient = new ProjectsClient();
      const project = await projectClient.getProjectDetails(this.projectId);

      this.projectName = project.name;
      this.projectOrganization = project.organizationName;

      const client = new DocumentsClient();
      this.documents = await client.projectDocumentSummary(this.projectId);

      if (this.documentIdInContext !== undefined) {
        await this.loadSections(this.documentIdInContext);
      }
    },

    async loadDocumentVersionReferences() {
      if (!this.documentVersionIdInContext) return;

      const referenceClient = new DocumentReferenceClient();
      this.documentVersionReferences = await referenceClient.getReferences(
        this.documentVersionIdInContext
      );
    },

    async refreshDocumentVersionAbbreviations() {
      if (!this.documentVersionIdInContext) return;

      const abbreviationsClient = new DocumentAbbreviationsClient();

      this.documentVersionAbbreviations = await abbreviationsClient.getAbbreviations(
        this.documentVersionIdInContext
      );
    },

    async refreshDocumentVersionVariables() {
      if (!this.documentVersionIdInContext) return;

      const variablesClient = new DocumentVariablesClient();

      this.documentVersionVariables = await variablesClient.getVariables(
        this.documentVersionIdInContext
      );
    },

    /**
     * Loads the sections for a document.
     */
    async loadSections(documentVersionId: string) {
      const client = new DocumentsClient();

      const model = await client.getDocumentDetails(documentVersionId);
      this.sectionDetailsTree = model.sections;
      this.saveReasons = model.saveReasons;
      this.flattenSections();
      this.documentVersionFontFamily = model.fontFamily;

      await this.loadSectionHeadings(documentVersionId);
    },

    async loadSectionHeadings(documentVersionId: string) {
      const client = new DocumentsClient();
      const documentSections = await client.getDocumentSections(documentVersionId);
      Vue.set(this.sectionHeadings, documentVersionId, documentSections);
    },

    orderSections() {
      this.orderSectionsRecursive(this.sectionDetailsTree, MAX_SUBSECTIONS + 1, undefined);
    },
    orderSectionsRecursive(
      sections: SectionDetails[],
      maxDepth: number,
      sectionNumber: number[] | undefined
    ) {
      if (maxDepth <= 0) return [];
      sections.sort((a, b) => a.order - b.order);
      sections.forEach((section, index) => {
        if (sectionNumber) {
          section.sectionNumber = [...sectionNumber, index];
        } else {
          section.sectionNumber = [index];
        }
        this.orderSectionsRecursive(section.subsections, maxDepth - 1, section.sectionNumber);
      });
    },

    getFlattenedSections() {
      return this.getFlattenedSectionDetailsRecursive(this.sectionDetailsTree, MAX_SUBSECTIONS + 1);
    },
    flattenSections() {
      this.flattenedSectionDetails = this.getFlattenedSectionDetailsRecursive(
        this.sectionDetailsTree,
        MAX_SUBSECTIONS + 1
      );
    },
    getFlattenedSectionDetailsRecursive(sections: SectionDetails[], maxDepth: number): SectionDetails[] {
      if (maxDepth <= 0) return [];

      let flattened: SectionDetails[] = [];

      sections.sort((a, b) => a.order - b.order);
      sections.forEach((section) => {
        let localFlattened: SectionDetails[] = [section];
        localFlattened = localFlattened.concat(
          this.getFlattenedSectionDetailsRecursive(section.subsections, maxDepth - 1)
        );
        flattened = flattened.concat(localFlattened);
      });

      return flattened;
    },

    getSectionFromSectionNumber(tree: SectionDetails[], treeIndex: number[]): SectionDetails {
      if (treeIndex.length > 1) {
        const newTreeIndex = [...treeIndex];
        const index = newTreeIndex.shift();
        const newTree = tree[index as number].subsections;
        return this.getSectionFromSectionNumber(newTree, newTreeIndex);
      } else {
        return tree[treeIndex[0]];
      }
    },

    getNextSectionOrder(tree: SectionDetails[]) {
      if (tree.length === 0) return 0;
      const last = tree[tree.length - 1];
      return last.order + 1;
    },

    async selectDocument(documentVersionId: string) {
      this.documentVersionIdInContext = documentVersionId;
      this.documentIdInContext = this.documentVersionInContext?.documentId;

      // We are entering into a different document.
      // Clear any section in context from the previous document.
      this.sectionIdInContext = undefined;

      this.documentVersionAbbreviations = [];
      await this.refreshDocumentVersionAbbreviations();

      this.documentVersionVariables = [];
      await this.refreshDocumentVersionVariables();

      this.documentVersionReferences = [];
      await this.loadDocumentVersionReferences();

      await this.loadSections(documentVersionId);
    },

    async selectSection(sectionId: string) {
      this.sectionIdInContext = sectionId;
      const section = this.flattenedSectionDetails.find((x) => x.id === sectionId);
      this.sectionVersionIdInContext = section?.sectionVersionDetails.id;
    },

    async createDocument(title: string, templateId: string) {
      const documentClient = new DocumentsClient();
      const model = {
        title: title,
        projectId: this.projectId,
        templateId: templateId
      } as CreateDocumentModel;

      await documentClient.create(model);

      //   await this.refreshProject();
    },

    async addDocumentVersion(documentId: string, isReviewVersion = false) {
      const client = new DocumentsClient();
      await client.createDocumentVersion(documentId, isReviewVersion);
    },

    async addSection(title: string, order: number, parentSectionId: string | null) {
      if (!this.documentVersionIdInContext) return;

      const client = new DocumentsClient();
      const model: ICreateSectionModel = {
        documentVersionId: this.documentVersionIdInContext,
        title: title,
        order: order,
        parentSectionId: parentSectionId ?? undefined
      };

      await client.createSection(new CreateSectionModel(model));

      //   await this.refreshProject();
    },

    async updateSection(
      sectionId: string,
      title: string,
      content: string,
      comment: string,
      reasonId: string | null
    ) {
      const client = new DocumentsClient();

      const model = {
        title: title,
        content: content,
        comment: comment,
        reasonId: reasonId
      } as UpdateSectionModel;

      await client.updateSection(sectionId, model);

      if (this.sectionIdInContext === sectionId) {
        this.sectionIdInContext = undefined;
      }
    },

    /**
     * Adds a new review to a section
     */
    async addReview(sectionId: string, content: string) {
      const client = new DocumentSectionReviewClient();

      const model: ICreateReviewModel = {
        documentSectionId: sectionId,
        content: content
      };

      await client.createReview(sectionId, new CreateReviewModel(model));

      if (this.sectionIdInContext === sectionId) {
        this.sectionIdInContext = undefined;
      }
    },

    async deleteSection(sectionId: string) {
      const client = new DocumentsClient();

      await client.deleteSection(sectionId);

      if (this.sectionIdInContext === sectionId) {
        this.sectionIdInContext = undefined;
      }
    },

    async receiveNewSectionVersion(model: SectionDetails) {
      //find the section in the tree
      var section = this.findSection(this.sectionDetailsTree, model.id);

      if (section) {
        section.sectionVersionDetails = model.sectionVersionDetails;
      }
      this.flattenSections();
      if (this.documentVersionIdInContext != null)
        await this.loadSectionHeadings(this.documentVersionIdInContext);
    },

    async receiveNewDocumentVersion(model: DocumentVersionHeadingModel) {
      const document = this.documents.find((x) => x.documentId === model.documentId);

      if (document === undefined) {
        // The document for the new version was not found.
        return;
      }

      if (document.versions.some((x) => x.documentVersionId === model.documentVersionId)) {
        // We are already tracking this version locally.
        return;
      }

      document.versions.push(model);
    },

    receiveNewDocument(model: DocumentHeadingModel) {
      this.documents.push(model);
    },

    updateDocumentName(documentId: string, name: string) {
      const document = this.documents.find((x) => x.documentId === documentId);

      if (!document) return;

      document.name = name;
    },

    updateDocumentVersionName(documentVersionId: string, name: string) {
      const documentVersion = this.documents
        .flatMap((x) => x.versions)
        .find((x) => x.documentVersionId == documentVersionId);

      if (!documentVersion) return;

      documentVersion.versionName = name;
    },

    updateDocumentVersionStatus(documentVersionId: string, status: number) {
      const documentVersion = this.documents
        .flatMap((x) => x.versions)
        .find((x) => x.documentVersionId == documentVersionId);

      if (!documentVersion) return;

      documentVersion.status = status as DocumentVersionStatus;
    },

    updateDocumentVersionFont(documentVersionId: string, fontFamily: FontFamily) {
      if (this.documentVersionIdInContext !== documentVersionId) return;

      this.documentVersionFontFamily = fontFamily;
    },

    async updateTemplateOrder(templateSectionId: string, order: number) {
      const client = new DocumentsClient();
      await client.updateSectionOrder(templateSectionId, { order } as UpdateSectionOrderRequest);
    },

    receiveNewReference(model: DocumentReferenceModel) {
      this.documentVersionReferences.push(model);
    },

    findSection(tree: SectionDetails[], id: string): SectionDetails | undefined {
      for (const section of tree) {
        if (section.id === id) return section;
        if (section.subsections) {
          const child = this.findSection(section.subsections, id);
          if (child) return child;
        }
      }
    },
    insertSection(tree: SectionDetails[], section: SectionDetails) {
      let insertIndex = 0;
      if (section.order !== 0) {
        insertIndex = tree.findIndex((x) => x.order == section.order);
      }

      if (insertIndex === -1) {
        tree.push(section);
      } else {
        for (let i = insertIndex; i < tree.length; i++) {
          const item = tree[i];
          item.order = item.order + 1;
        }
        tree.splice(insertIndex, 0, section);
      }
      this.orderSections();
    },

    updateReference(model: DocumentReferenceModel) {
      const reference = this.documentVersionReferences.find((x) => x.id == model.id);

      if (!reference) return;

      reference.version.details = model.version.details;
    },

    receiveNewAbbreviation(model: DocumentAbbreviationModel) {
      this.documentVersionAbbreviations.push(model);
    },

    updateAbbreviation(model: DocumentAbbreviationModel) {
      const abbreviation = this.documentVersionAbbreviations.find((x) => x.id == model.id);

      if (!abbreviation) return;

      abbreviation.version.value = model.version.value;
    },

    receiveNewVariable(model: DocumentVariableModel) {
      this.documentVersionVariables.push(model);
    },

    updateVariable(model: DocumentVariableModel) {
      const variable = this.documentVersionVariables.find((x) => x.id == model.id);

      if (!variable) return;

      variable.version.value = model.version.value;
    },

    /**
     * Add a new review to the list of reviews for a section
     */
    async receiveNewReview(model: SectionReviewModel) {
      var section = this.findSection(this.sectionDetailsTree, model.originatingSectionId);
      if (!section) {
        return;
      }

      if (section.sectionReview == undefined) {
        const reviewModel: IDocumentSectionReviewModel = {
          documentSectionId: model.originatingSectionId,
          openReviewCount: 0,
          originatingDocumentVersionName: "",
          reviews: []
        };
        section.sectionReview = new DocumentSectionReviewModel(reviewModel);
      }

      section.sectionReview.openReviewCount += 1;
      section.sectionReview.reviews.unshift(model);

      this.flattenSections();
    },

    /**
     * Mark a review as rejected
     */
    async rejectReviewAsync(reviewId: string) {
      const documentClient = new DocumentSectionReviewClient();
      await documentClient.reject(reviewId);
    },

    /**
     * Mark a review as accepted with changes
     */
    async acceptReviewWithChangesAsync(reviewId: string, newContent: string) {
      const documentClient = new DocumentSectionReviewClient();
      const model: IAcceptReviewWithChangesModel = {
        content: newContent
      };
      await documentClient.acceptWithChanges(reviewId, new AcceptReviewWithChangesModel(model));
    },

    /**
     * Marks a review as being resolved for a section
     */
    async receiveReviewStatusUpdated(model: SectionReviewModel) {
      var section = this.findSection(this.sectionDetailsTree, model.originatingSectionId);
      if (!section || !section.sectionReview) {
        return;
      }
      const reviewIndex = section.sectionReview.reviews.findIndex(
        (x) => x.documentSectionReviewId == model.documentSectionReviewId
      );
      if (reviewIndex !== -1) {
        section.sectionReview.reviews[reviewIndex] = model;
        section.sectionReview.openReviewCount = section.sectionReview.openReviewCount - 1;
      }
      this.flattenSections();
    },

    async receiveNewComment(model: SectionCommentModel) {
      if (this.documentVersionIdInContext != null) {
        var section = this.findSection(this.sectionDetailsTree, model.originatingSectionId);
        if (!section) {
          return;
        }

        section.openCommentCount = section.openCommentCount + 1;
        const comment = section.comments[0];
        comment.openCommentCount = comment.openCommentCount + 1;
        comment.comments.unshift(model);

        this.flattenSections();
      }
    },

    async receiveCommentResolved(model: SectionCommentModel) {
      if (this.documentVersionIdInContext != null) {
        var section = this.findSection(this.sectionDetailsTree, model.originatingSectionId);
        if (!section) {
          return;
        }
        const comment = section.comments[0];
        const commentIndex = comment.comments.findIndex(
          (x) => x.documentSectionCommentId == model.documentSectionCommentId
        );
        if (commentIndex !== -1) {
          comment.comments[commentIndex] = model;
          section.openCommentCount = section.openCommentCount - 1;
          comment.openCommentCount = comment.openCommentCount - 1;
        }
        this.flattenSections();
      }
    },

    async receiveNewSection(model: SectionDetails) {
      if (model.parentSectionId) {
        const parentSection = this.findSection(this.sectionDetailsTree, model.parentSectionId);
        if (!parentSection) return;
        this.insertSection(parentSection.subsections, model);
      } else {
        this.insertSection(this.sectionDetailsTree, model);
      }
      this.flattenSections();
      if (this.documentVersionIdInContext != null)
        await this.loadSectionHeadings(this.documentVersionIdInContext);
    },

    mapOrderingLookupRecursive(lookupMap: { [key: string]: number }) {},
    async refreshOrdering(model: SectionDetails) {
      //remove this section from the tree
      this.removeSectionRecursive(this.sectionDetailsTree, model.id);
      if (model.parentSectionId) {
        const parentSection = this.findSection(this.sectionDetailsTree, model.parentSectionId);
        if (!parentSection) return;
        this.insertSection(parentSection.subsections, model);
      } else {
        this.insertSection(this.sectionDetailsTree, model);
      }
      this.flattenSections();
      if (this.documentVersionIdInContext != null)
        await this.loadSectionHeadings(this.documentVersionIdInContext);
    },

    async removeSection(documentVersionId: string, documentSectionId: string) {
      this.removeSectionRecursive(this.sectionDetailsTree, documentSectionId);
      this.orderSections();
      this.flattenSections();

      if (this.documentVersionIdInContext != null)
        await this.loadSectionHeadings(this.documentVersionIdInContext);
    },
    removeSectionRecursive(tree: SectionDetails[], sectionId: string) {
      tree.forEach((section, index) => {
        if (section.id === sectionId) {
          tree.splice(index, 1);
          return;
        }
        if (section.subsections) {
          this.removeSectionRecursive(section.subsections, sectionId);
        }
      });
    }
  }
});
