



































































































































































































































































































import { ref, defineComponent, onMounted, onUnmounted } from "@vue/composition-api";
import Editor from "../Editor/Editor.vue";

import {
  TemplatesClient,
  TemplateEditorClient,
  UpdateTemplateTagsRequest,
  RenameTemplateModel,
  UpdateTemplateSummaryModel,
  TemplateVariableModel,
  TemplateAbbreviationModel
} from "@/api/OtiumAppApi";
import { TemplatePartType, TemplateTree, useTemplateEditorStore } from "@/stores/templateEditorStore";
import TemplateSection from "./TemplateSection.vue";
import TemplateSectionEditor from "./TemplateSectionEditor.vue";
import TagSelector from "./TagSelector.vue";
import { templateEditorHub } from "@/hubs/templateEditorHub";
import AuditTimelineDialog from "./AuditTimelineDialog.vue";
import VariablesList from "./VariablesList.vue";
import AbbreviationsList from "./AbbreviationsList.vue";
import OtiumBasePage from "../OtiumBasePage.vue";
import Sidebar from "../Editor/Sidebar.vue";
import CreateSectionDialog from "./CreateSectionDialog.vue";
import RenameTemplateDialog from "./RenameTemplateDialog.vue";
import TemplateSummaryDialog from "./TemplateSummaryDialog.vue";
import ArchiveTemplateDialog from "./ArchiveTemplateDialog.vue";
import RenameTemplateVersionDialog from "./RenameTemplateVersionDialog.vue";

export default defineComponent({
  components: {
    OtiumBasePage,
    Editor,
    TemplateSection,
    TemplateSectionEditor,
    TagSelector,
    AuditTimelineDialog,
    VariablesList,
    AbbreviationsList,
    Sidebar,
    CreateSectionDialog,
    RenameTemplateDialog,
    TemplateSummaryDialog,
    ArchiveTemplateDialog,
    RenameTemplateVersionDialog
  },

  props: {
    templateId: {
      type: String,
      required: true
    }
  },
  setup(props, { root, refs }) {
    const navDrawer = ref(null);
    const templateStore = useTemplateEditorStore();
    const rules = [(v: string) => !!v || "Required"];
    const client = new TemplateEditorClient();
    const templatesClient = new TemplatesClient();
    const displayAddSectionDialog = ref(false);

    const getSections = async (item: TemplateTree) => {
      await templateStore.loadSections(item.id);
    };

    const newSectionParentSectionId = ref(null as string | null);
    const newSectionOrder = ref(0);
    const newSectionParentSectionName = ref("");
    const sectionElements = ref<HTMLElement[]>([]);

    const createSubsection = (sectionData: any) => {
      newSectionOrder.value = templateStore.getNextSectionOrder(sectionData.subsections);
      newSectionParentSectionName.value = sectionData.sectionNumber + ". " + sectionData.sectionTitle;
      newSectionParentSectionId.value = sectionData.sectionId;
      displayAddSectionDialog.value = true;
    };

    const isSelectingTemplate = ref(false);
    const updateSelectedTemplateVersion = async (item: TemplateTree[]) => {
      if (item.length === 0) {
        return;
      }

      const activeItem = item[0];
      isSelectingTemplate.value = true;
      switch (activeItem.type) {
        case TemplatePartType.TemplateVersion:
          await templateStore.selectTemplateVersion(activeItem.id);
          break;
        case TemplatePartType.Section: {
          if (
            !templateStore.hasTemplateVersionInContext ||
            templateStore.templateVersionIdInContext !== activeItem.templateVersionId
          ) {
            await templateStore.selectTemplateVersion(activeItem.templateVersionId);
          }
          const sectionIndex = templateStore.flattenedSectionDetails.findIndex(
            (sectionDetail) => sectionDetail.sectionId === activeItem.id
          );
          if (sectionIndex === -1) break;
          const section = sectionElements.value[sectionIndex];
          if (section != undefined) section.scrollIntoView({ block: "center", behavior: "smooth" });
          break;
        }
      }
      isSelectingTemplate.value = false;
    };

    const isLoadingTemplate = ref(false);
    onMounted(async () => {
      isLoadingTemplate.value = true;
      await templateEditorHub.start();
      await templateEditorHub.joinTemplate(props.templateId);
      await templateStore.loadTemplate(props.templateId);
      tagIds.value = templateStore.template?.tags.map((t) => t.tagId) ?? [];
      isLoadingTemplate.value = false;
    });

    onUnmounted(async () => {
      await templateEditorHub.stop();
      await templateStore.$reset();
    });

    //rename template
    const displayRenameDialog = ref(false);
    const saveTemplateName = async (tplName: string) => {
      await client.renameTemplate(props.templateId, {
        name: tplName
      } as RenameTemplateModel);

      displayRenameDialog.value = false;
    };

    //change summary
    const displayChangeSummaryDialog = ref(false);
    const saveTemplateSummary = async (tplSummary: string) => {
      await client.updateTemplateSummary(props.templateId, {
        summary: tplSummary
      } as UpdateTemplateSummaryModel);
      displayChangeSummaryDialog.value = false;
    };

    //archive template
    const displayArchiveDialog = ref(false);
    const archiveTemplate = async () => {
      if (templateStore.templateId == undefined) return;
      await templatesClient.archiveTemplate(templateStore.templateId);
      displayArchiveDialog.value = false;
      root.$router.push({ name: "ArchivedTemplates" });
    };

    //template tags
    const tagIds = ref<string[]>([]);
    async function saveTags(tags: string[]) {
      const request = new UpdateTemplateTagsRequest({
        tagIds: tagIds.value
      });
      client.updateTags(props.templateId, request);
    }

    //publish template
    const publishing = ref(false);
    const publish = async () => {
      publishing.value = true;
      await templateStore.publishVersion(templateStore.templateVersionIdInContext);
      publishing.value = false;
    };

    //clear publish
    const clearPublishedVersion = async () => {
      publishing.value = true;
      await templateStore.publishVersion(undefined);
      publishing.value = false;
    };

    const addVersion = async () => {
      await templateStore.createVersion();
    };

    // renaming versions
    const displayVersionRenameDialog = ref(false);
    const templateVersionName = ref("");
    const renameVersionId = ref("");
    const openVersionRenameDialog = (templateVersion: TemplateTree) => {
      renameVersionId.value = templateVersion.templateVersionId;
      templateVersionName.value = templateVersion.name;
      displayVersionRenameDialog.value = true;
    };
    const saveTemplateVersionName = async (tplName: string) => {
      if (renameVersionId.value == null) return;

      await client.renameTemplateVersion(renameVersionId.value, {
        name: tplName
      } as RenameTemplateModel);

      displayVersionRenameDialog.value = false;
    };

    const searchTerm = ref("");

    const openedAbbreviationDialogs = ref({ add: false, edit: false });
    const editingAbbreviation = ref<TemplateAbbreviationModel>();
    const onEditAbbreviationClick = (abbreviation: TemplateAbbreviationModel) => {
      openedAbbreviationDialogs.value.edit = true;
      editingAbbreviation.value = abbreviation;
    };

    const openedVariableDialogs = ref({ add: false, edit: false });
    const editingVariable = ref<TemplateVariableModel>();
    const onEditVariableClick = (variable: TemplateVariableModel) => {
      openedVariableDialogs.value.edit = true;
      editingVariable.value = variable;
    };

    const getTemplateVersion = () => {
      return templateVersionName.value;
    };

    return {
      updateSelectedTemplateVersion,
      getSections,
      displayAddSectionDialog,
      searchTerm,
      rules,

      sectionElements,

      displayRenameDialog,

      saveTemplateName,

      displayChangeSummaryDialog,
      saveTemplateSummary,
      displayArchiveDialog,
      archiveTemplate,

      templateStore,
      navDrawer,
      tagIds,
      saveTags,
      publish,
      publishing,
      addVersion,

      TemplatePartType,
      displayVersionRenameDialog,
      openVersionRenameDialog,
      renameVersionId,
      templateVersionName,
      getTemplateVersion,

      saveTemplateVersionName,
      clearPublishedVersion,
      newSectionParentSectionId,
      newSectionOrder,
      createSubsection,
      newSectionParentSectionName,

      openedAbbreviationDialogs,
      editingAbbreviation,
      onEditAbbreviationClick,

      openedVariableDialogs,
      editingVariable,
      onEditVariableClick,
      isLoadingTemplate,
      isSelectingTemplate
    };
  }
});
