





































import { TagCategoryModel, TagsClient } from "@/api/OtiumAppApi";
import { ref, defineComponent, PropType, toRefs, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);

    // update the local value when the v-model updates
    watch(value, () => {
      selectedTags.value = value.value;
    });

    const showDialog = ref(false);
    const tagCategories = ref<TagCategoryModel[]>([]);
    const selectedTags = ref<string[]>([]);

    const getCategories = async () => {
      const client = new TagsClient();
      tagCategories.value = await client.getCategories();
    };
    getCategories();

    const close = () => {
      showDialog.value = false;
      emit("close", selectedTags.value);
    };

    return {
      showDialog,
      tagCategories,
      selectedTags,
      close
    };
  }
});
