import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ReferenceWidget from "./SectionLinkWidget.vue";

export default Node.create({
  name: "otiumLink",

  group: "inline",
  inline: true,
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      name: {
        default: "test"
      },
      type: {
        default: "test"
      },
      reference: {
        default: "test"
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "otium-link"
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["otium-link", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(ReferenceWidget);
  }
});
