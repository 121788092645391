












import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { defineComponent, ref, onUnmounted, onMounted } from "@vue/composition-api";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import TextColor from "@tiptap/extension-color";
import ProjectNodeCreate from "./widgets/ProjectVariable/Extensions";
import TemplateNodeCreate from "./widgets/TemplateVariable/Extensions";
import {
  TemplateAbbreviation,
  TemplateVerboseAbbreviation
} from "./widgets/TemplateAbbreviation/Extensions";
import {
  ProjectAbbreviation,
  ProjectVerboseAbbreviation
} from "./widgets/ProjectAbbreviation/Extensions";
import ProjectChartNodeCreate from "./widgets/ProjectChart/Extensions";
import ReferenceNodeCreate from "./widgets/DocumentReference/Extensions";
import TextTransform from "./widgets/TextTransform";
import SectionLink from "./widgets/DocumentSectionLink/Extension";
import OtiumTable from "@/components/Editor/widgets/Tables/Extension";
import InsertedNode from "./widgets/InsertedNode";
import { useEditorStore } from "@/stores/editorStore";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    isDocument: {
      type: Boolean,
      default: true
    }
  },
  components: {
    EditorContent
  },

  setup(props, ctx) {
    const editor = ref(
      new Editor({
        content: props.value || "",

        editorProps: {
          transformPastedText(text) {
            return text;
          },
          transformPastedHTML(html) {
            let bodyStartIndex = html.indexOf("<body");

            // If there is no body tag, return the html as is.
            if (bodyStartIndex <= 0) return html;

            // Grab the content of the body
            let body = html.substring(bodyStartIndex);
            body = body.replace(/(?:\r\n|\r|\n)/g, ""); // Strip out all forms of new lines

            // Set regex expression for the stuff we want to strip out of text
            let regex = "class='(.|\n)*?'"; // Anything with a class attributate
            regex += "|style='(.|\n)*?'"; // ANhything with a style attribute
            regex += "|<body(.|\n)*?>|</body>"; // Any body tags
            regex += "|<html(.|\n)*?>|</html>"; // Any html tags
            regex += "|<!--EndFragment-->|<!--StartFragment-->"; // Fragment comments

            const re = new RegExp(regex, "gi");

            // Return the formatted string
            return body.replaceAll(re, "");
          }
        },

        onUpdate: () => {
          if (!editor.value) return;
          ctx.emit("input", editor.value.getHTML());
        },
        extensions: [
          InsertedNode,
          StarterKit,
          Table.configure({
            resizable: true
          }),
          Placeholder.configure({
            placeholder: "Start typing here."
          }),
          Underline,
          TableRow,
          TableHeader,
          TableCell,
          Image.configure({
            allowBase64: true
          }),
          TextAlign.configure({
            types: ["heading", "paragraph"]
          }),
          TextStyle,
          FontFamily,
          TextTransform,
          props.isDocument ? ProjectNodeCreate : TemplateNodeCreate,
          props.isDocument ? ProjectAbbreviation : TemplateAbbreviation,
          props.isDocument ? ProjectVerboseAbbreviation : TemplateVerboseAbbreviation,
          ProjectChartNodeCreate,
          ReferenceNodeCreate,
          TextColor,
          SectionLink,
          OtiumTable
        ]
      })
    );

    const editorStore = useEditorStore();

    onMounted(() => {
      editorStore.editor = editor as any;
    });

    onUnmounted(() => {
      editor.value?.destroy();
      editorStore.editor?.destroy();

      editorStore.editor = null;
      editorStore.$reset();
    });

    return {
      editorStore
    };
  }
});
