


























































































































import { TemplateSectionEditModel } from "@/api/OtiumAppApi";
import { useTemplateEditorStore } from "@/stores/templateEditorStore";
import { computed, defineComponent, ref } from "@vue/composition-api";
import Editor from "../Editor/Editor.vue";

export default defineComponent({
  props: {
    sectionId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const templateStore = useTemplateEditorStore();
    const name = ref(templateStore.sectionDetailsInContext?.version.title ?? "");
    const contents = ref(templateStore.sectionDetailsInContext?.version.content ?? "");

    const confirmDiscard = ref(false);

    const confirmSave = ref(false);

    const showMoveDialog = ref(false);
    const newSectionOrder = ref(null as number | null);

    const saving = ref(false);
    const save = async () => {
      saving.value = true;

      await templateStore.updateSection(props.sectionId, name.value, contents.value);

      saving.value = false;
      confirmSave.value = false;
    };

    const discard = () => {
      templateStore.sectionIdInContext = undefined;

      confirmDiscard.value = false;
    };

    const sectionsForReorder = computed(() => {
      let treeIndex = templateStore.sectionDetailsInContext?.sectionNumber;
      if (treeIndex == null) return [];
      treeIndex = [...treeIndex];
      treeIndex.pop(); // we want to get the parent of the section
      let sections: TemplateSectionEditModel[];
      if (treeIndex.length > 0) {
        sections = templateStore.getSectionFromSectionNumber(
          templateStore.sectionDetailsTree,
          treeIndex
        ).subsections;
      } else {
        sections = templateStore.sectionDetailsTree;
      }
      const sectionOptions = sections.map((s) => ({ value: s.order, text: s.version.title }));
      const lastSection =
        sectionOptions.length > 0 ? sectionOptions[sectionOptions.length - 1].value : 0;
      sectionOptions.push({ value: lastSection + 1, text: "< move to end >" });
      return sectionOptions;
    });

    const reorderingSection = ref(false);
    const reorderSection = async () => {
      if (templateStore.sectionIdInContext && newSectionOrder.value !== null) {
        reorderingSection.value = true;
        await templateStore.updateTemplateOrder(templateStore.sectionIdInContext, newSectionOrder.value);
      }
      reorderingSection.value = false;
      showMoveDialog.value = false;
    };

    const confirmDelete = ref(false);
    const deleteSection = async () => {
      await templateStore.deleteSection(props.sectionId);
      confirmDelete.value = false;
    };

    return {
      name,
      contents,
      confirmDiscard,
      confirmDelete,
      confirmSave,
      showMoveDialog,
      newSectionOrder,
      sectionsForReorder,
      reorderingSection,
      reorderSection,
      saving,
      save,
      discard,
      deleteSection
    };
  },
  components: { Editor }
});
