










































import { defineComponent, ref } from "@vue/composition-api";
import { useProjectStore } from "@/stores/projectStore";

export default defineComponent({
  name: "SectionLinkDialog",
  components: {},
  model: {
    prop: "data",
    event: "save"
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    isDocument: {
      type: Boolean,
      required: true
    }
  },
  emits: ["save", "cancel"],
  setup(props, { emit }) {
    let description = ref("");
    let sectionId = ref("");
    const projectStore = useProjectStore();

    const sections = projectStore.getFlattenedSections();

    function save() {
      emit("save", {
        id: sectionId.value,
        value: description.value
      });
    }
    function close() {
      emit("close");
    }

    return {
      description,
      save,
      close,
      sections,
      sectionId
    };
  }
});
