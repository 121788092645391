



































import { defineComponent, ref } from "@vue/composition-api";

interface options {
  value: string;
  text: string;
}

export default defineComponent({
  name: "ChartLinkDialog",
  components: {},
  model: {
    prop: "data",
    event: "save"
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    isDocument: {
      type: Boolean,
      required: true
    }
  },
  emits: ["save", "cancel"],
  setup(props, { emit }) {
    let description = ref("");
    let sectionId = ref("");

    const sections = document.getElementsByClassName("tablespan");
    let options: options[] = [];
    for (let i = 0; i < sections.length; i++) {
      var section = sections[i];
      const name = section.getAttribute("title");
      const id = section.getAttribute("tableid");

      if (name != null && id != null) {
        options.push({
          value: id,
          text: name
        });
      }
    }

    function save() {
      emit("save", {
        id: sectionId.value,
        value: description.value
      });
    }

    function close() {
      emit("close");
    }

    return {
      description,
      save,
      close,
      options,
      sectionId
    };
  }
});
