
















































import { defineComponent, ref, onMounted } from "@vue/composition-api";
export default defineComponent({
  name: "RenameTemplateDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    templateName: {
      type: String,
      required: true
    }
  },
  emits: ["close", "save"],
  setup(props, { emit, refs }) {
    const isRenameFormValid = ref(false);
    const newTemplateName = ref("");
    const savingTemplateName = ref(false);
    const rules = [(v: string) => !!v || "Required"];
    const saveTemplateNameClicked = () => {
      (refs.renameForm as HTMLFormElement).validate();
      if (!isRenameFormValid) {
        return;
      }
      savingTemplateName.value = true;
      emit("save", newTemplateName.value);
      savingTemplateName.value = false;
      (refs.renameForm as HTMLFormElement).reset();
    };
    function close() {
      (refs.renameForm as HTMLFormElement).reset();
      emit("close");
    }
    onMounted(() => {
      newTemplateName.value = props.templateName;
    });

    return {
      close,
      isRenameFormValid,
      newTemplateName,
      savingTemplateName,
      saveTemplateNameClicked,
      rules
    };
  },
  components: {}
});
