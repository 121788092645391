












































































import { ref, defineComponent, watch, toRefs } from "@vue/composition-api";
import { TemplateSectionVersionDetails, TemplateAuditClient, TemplatesClient, TemplateSectionAuditEntry } from "@/api/OtiumAppApi";

export default defineComponent({
  props: {
    templateVersionId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { templateVersionId } = toRefs(props);
    const showDialog = ref(false);
    const auditEntries = ref<TemplateSectionAuditEntry[]>([]);

    const loadTimeline = async () => {
      const client = new TemplateAuditClient();
      auditEntries.value = await client.getTimelineForTemplateVersion(props.templateVersionId);
    };
    watch(templateVersionId, loadTimeline);

    const openDialog = async () => {
      selectedVersion.value = null;
      await loadTimeline();
      showDialog.value = true;
    };

    const selectedVersion = ref<TemplateSectionVersionDetails | null>(null);
    const previousVersion = ref<TemplateSectionVersionDetails | null>(null);

    const handleEntryClick = async (entry: TemplateSectionAuditEntry) => {
      const templatesClient = new TemplatesClient();
      selectedVersion.value = await templatesClient.getSectionVersionDetails(entry.templateSectionVersionId);

      const previousEntry = await getPreviousEntry(entry);
      if (previousEntry) {
        previousVersion.value = await templatesClient.getSectionVersionDetails(previousEntry.templateSectionVersionId);
      } else {
        previousVersion.value = null;
      }
    };

    const getPreviousEntry = async (entry: TemplateSectionAuditEntry) => {
      const documentAuditClient = new TemplateAuditClient();
      const sectionEntries = await documentAuditClient.getTimelineForSection(entry.templateSectionId);
      const selectedIndex = sectionEntries.findIndex((x) => x.templateSectionVersionId === entry.templateSectionVersionId);
      if (selectedIndex <= 0) return;
      return sectionEntries[selectedIndex - 1];
    };

    return {
      showDialog,
      auditEntries,
      openDialog,
      handleEntryClick,
      selectedVersion,
      previousVersion
    };
  }
});
