





import { SectionDetails } from "@/api/OtiumAppApi";
import { useProjectStore } from "@/stores/projectStore";
import { computed, defineComponent } from "@vue/composition-api";
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";
import NodeProps from "../NodeProps";
export default defineComponent({
  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  props: NodeProps,
  setup(props) {
    const store = useProjectStore();

    const abbreviationName = computed(() => {
      return props.node?.attrs.name.toString();
    });

    const abbreviation = computed(() => {
      return store.documentVersionAbbreviations.find((x) => x.name === abbreviationName.value);
    });

    const value = computed(() => {
      return abbreviation.value?.version.value ?? "";
    });

    return {
      store,
      value,
      abbreviationName,
      abbreviation
    };
  }
});
